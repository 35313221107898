import { createBrowserRouter } from "react-router-dom";
import Login from "@app/pages/public/login/Login";
import NotFound from "@app/pages/public/not-found/NotFound";
import Signup from "@app/pages/public/signup/Signup";
import Terms from "@app/pages/public/terms/Terms";
import PrivacyPolicy from "@app/pages/public/privacy-policy/PrivacyPolicy";
import RootLayout from "@app/layouts/RootLayout";
import AuthLayout from "@app/layouts/AuthLayout";
import { indexRoutes } from "./urls";
import { WebsocketProvider } from "@app/contexts/websocket-provider";
import { userRouter } from "./user/user-routes";
import { adminRoutes } from "./admin/urls";
import { userRoutes } from "./user/urls";
import { workerRoutes } from "./worker/urls";
import { adminRouter } from "./admin/admin-routes";
import { workerRouter } from "./worker/worker-routes";
import AdminLayout from "@app/layouts/admin/AdminLayout";
import UserLayout from "@app/layouts/user/UserLayout";
import WorkerLayout from "@app/layouts/worker/WorkerLayout";
import Subscription from "@app/pages/public/subscription/Subscription";
import AskResetPassword from "@app/pages/public/ask-reset-password/AskResetPassword";
import ResetPassword from "@app/pages/public/reset-password/ResetPassword";

export const router = createBrowserRouter([
  {
    path: indexRoutes.BASENAME,
    element: <RootLayout />,
    children: [
      {
        path: indexRoutes.BASENAME,
        element: <Login />,
      },
      {
        path: indexRoutes.LOGIN,
        element: <Login />,
      },
      {
        path: indexRoutes.SIGNUP,
        element: <Signup />,
      },
      {
        path: indexRoutes.ASK_RESET_PASSWORD,
        element: <AskResetPassword />,
      },
      {
        path: indexRoutes.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: indexRoutes.TERMS,
        element: <Terms />,
      },
      {
        path: indexRoutes.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: userRoutes.SUBSCRIPTION,
        element: <Subscription />,
      },
      {
        path: indexRoutes.ALL, // 404
        element: <NotFound />,
      },
      {
        path: indexRoutes.AUTH,
        element: <AuthLayout />,
      },
      {
        path: adminRoutes.BASENAME,
        element: <AdminLayout />,
        children: adminRouter,
      },
      {
        path: userRoutes.BASENAME,
        element: (
          <WebsocketProvider>
            <UserLayout />
          </WebsocketProvider>
        ),
        children: userRouter,
      },
      {
        path: workerRoutes.BASENAME,
        element: <WorkerLayout />,
        children: workerRouter,
      },
    ],
  },
]);

export type AppRoutes = typeof router;
