import React from "react";
import { LucideIcon } from "lucide-react";
import { cn } from "@lib/utils";
import { Button } from "@app/components/ui/button";
import { Tooltip, TooltipTrigger, TooltipContent } from "@app/components/ui/tooltip";

interface Props {
  isCollapsed: boolean;
  links: {
    title: string;
    label?: string;
    action: () => void;
    icon: LucideIcon;
    variant: "secondary" | "ghost";
  }[];
}

const Nav: React.FC<Props> = ({ links, isCollapsed }) => {
  const handle = (link: Props["links"][number]) => {
    link.action();
  };

  return (
    <div data-collapsed={isCollapsed} className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2">
      <nav className="grid gap-3 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <Button onClick={() => handle(link)} variant={link.variant}>
                  <link.icon className="h-4 w-4" />
                  <span className="sr-only">{link.title}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right" className="hidden md:flex items-center gap-4">
                {link.title}
                {link.label && <span className="ml-auto text-muted-foreground">{link.label}</span>}
              </TooltipContent>
            </Tooltip>
          ) : (
            <Button
              key={index}
              variant={link.variant}
              onClick={() => handle(link)}
              className={cn("justify-center md:justify-start")}
            >
              <link.icon className="md:mr-2 h-4 w-4" />
              <span className="hidden md:inline-block">{link.title}</span>
              {link.label && (
                <span className={cn("ml-auto", link.variant === "secondary" && "text-black dark:text-white")}>
                  {link.label}
                </span>
              )}
            </Button>
          )
        )}
      </nav>
    </div>
  );
};

export default Nav;
