import { CreateTask, Message, Task, TaskGateway, TaskRoleMessage, TaskStatus } from "@core/gateways/task/taskGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseTaskGateway implements TaskGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async getTask(threadId: string): Promise<Task | null> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { data: task, error } = await this.cloud
        .from("task")
        .update({ read: true })
        .eq("threadId", threadId)
        .eq("user", session.user.id)
        .select("*")
        .single();

      if (error) {
        this.toast.show("⛔️ Error", `Error getting task ${error.message}`);
        return null;
      }

      const { data: messages } = await this.cloud.from("message").select("*").eq("task", task.id);

      return {
        ...task,
        messages: messages ?? [],
        read: true,
      };
    } catch (error) {
      this.toast.show("⛔️ Error", `Error getting task`);
      return null;
    }
  }

  async getTasks(status?: Task["status"]): Promise<Omit<Task, "messages">[]> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      if (status) {
        const { data: tasks, error } = await this.cloud
          .from("task")
          .select("*")
          .eq("user", session.user.id)
          .order("updated_at", { ascending: false })
          .contains("status", status);

        if (error) {
          this.toast.show("⛔️ Error", `Error getting tasks ${error.message}`);
          return [];
        }

        return tasks ?? [];
      }

      const { data: tasks, error } = await this.cloud
        .from("task")
        .select("*")
        .eq("user", session.user.id)
        .order("updated_at", { ascending: false });

      if (error) return [];

      return tasks ?? [];
    } catch (error) {
      this.toast.show("⛔️ Error", `Error getting tasks`);
      return [];
    }
  }

  async createTask(task: CreateTask): Promise<Task> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { data: newTask, error } = await this.cloud
        .from("task")
        .update({ status: "in_progress" })
        .eq("user", session.user.id)
        .eq("threadId", task.threadId)
        .select("id, created_at, updated_at")
        .single();

      await this.cloud.from("message").delete().eq("user", session.user.id).eq("threadId", task.threadId);

      if (error) {
        this.toast.show("⛔️ Error", `Error creating task ${error.message}`);
        throw new Error("Error creating task");
      }

      this.toast.show("✅ Success", "Task created successfully");
      return {
        id: newTask.id,
        user: session.user.id,
        created_at: newTask.created_at,
        title: task.title,
        subTitle: task.subTitle,
        description: task.description,
        updated_at: newTask.updated_at,
        read: true,
        messages: [],
        status: "in_progress",
        threadId: task.threadId,
        planeIssueId: null,
      };
    } catch (error) {
      this.toast.show("⛔️ Error", `Error creating task`);
      throw new Error("Error creating task");
    }
  }

  async updateTask(task: Task): Promise<Task> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { error } = await this.cloud
        .from("task")
        .update({ read: true })
        .eq("threadId", task.threadId)
        .eq("id", task.id)
        .eq("user", session.user.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error updating task ${error.message}`);
        throw new Error("Error updating task");
      }

      this.toast.show("✅ Success", "Task updated successfully");
      return task;
    } catch (error) {
      this.toast.show("⛔️ Error", `Error updating task`);
      throw new Error("Error updating task");
    }
  }

  async updateTaskStatus(task: Task, status: TaskStatus): Promise<Task> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { error } = await this.cloud
        .from("task")
        .update({
          status: status,
        })
        .eq("threadId", task.threadId)
        .eq("id", task.id)
        .eq("user", session.user.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error updating task status:  ${error.message}`);
        throw new Error("Error updating task");
      }

      this.toast.show("✅ Success", "Task status updated successfully");
      return { ...task, status };
    } catch (error) {
      this.toast.show("⛔️ Error", `Error updating task status`);
      throw new Error("Error updating task");
    }
  }

  async postTaskMessage(
    message: string,
    task: { id: Task["id"]; threadId: Task["threadId"] },
    byPass?: boolean
  ): Promise<Message | boolean> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;
      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { data: newMessage } = await this.cloud
        .from("message")
        .insert({
          task: task.id,
          threadId: task.threadId,
          user: session.user.id,
          role: "user",
          content: message,
        })
        .select("*")
        .single();

      if (!newMessage) throw new Error("Error posting message");

      return byPass ?? newMessage;
    } catch (error) {
      this.toast.show("⛔️ Error", `Error posting message`);
      throw new Error("Error posting message");
    }
  }

  async getTaskMessages(task: Task, role: TaskRoleMessage[] = []): Promise<Message[]> {
    let roles = "";

    role.map((el, i) => (roles += `role.eq.${el}${role.length === i + 1 ? `` : `,`}`));

    try {
      const { data: messages } = await this.cloud.from("message").select("*").eq("task", task.id).or(roles);

      return messages ?? [];
    } catch (error) {
      this.toast.show("⛔️ Error", `Error getting messages`);
      return [];
    }
  }

  async deleteTask(task: Task): Promise<Task | null> {
    try {
      const { error } = await this.cloud.from("task").delete().eq("id", task.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error deleting task ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Task deleted successfully");
      return task;
    } catch (error) {
      this.toast.show("⛔️ Error", `Error deleting task`);
      return null;
    }
  }
}
