import { createReducer } from "@reduxjs/toolkit";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { AppState } from "@store/appState";
import { getTaskAction } from "@core/usecases/task/get-task/getTaskAction";
import { getTasksAction } from "@core/usecases/task/get-tasks/getTasksAction";
import { updateTaskAction } from "@core/usecases/task/update-task/updateTaskAction";
import { createTaskAction } from "@core/usecases/task/create-task/createTaskAction";
import { updateOnCreateTaskAction } from "@core/usecases/task/update-oncreate-task/updateOnCreateTaskAction";
import { clearTaskAction } from "@core/usecases/task/clear-task/clearTaskAction";
import { getTaskMessagesAction } from "@core/usecases/task/get-task-messages/getTaskMessagesAction";
import { postTaskMessageAction } from "@core/usecases/task/post-message/postTaskMessageAction";
import { receiveTaskMessageAction } from "@core/usecases/task/receive-task-message/receiveTaskMessageAction";
import { updateTaskStatusAction } from "@core/usecases/task/update-task-status/updateTaskStatusAction";
import { initOnCreateTaskAction } from "@core/usecases/task/init-oncreate-task/initOnCreateTaskAction";
import { deleteTaskAction } from "@core/usecases/task/delete-task/deleteTaskAction";

const initialState: AppState["task"] = {
  list: [],
  selected: null,
};

export const taskReducer = createReducer<AppState["task"]>(initialState, builder => {
  /**
   * Get tasks
   */
  builder.addCase(getTasksAction, (state, { payload }) => {
    if (!payload) return;

    state.list = payload;
  });

  /**
   * Get task
   */
  builder.addCase(getTaskAction, (state, { payload }) => {
    if (!payload) return;

    state.selected = payload;
    const oldTaskIndex = state.list.findIndex(task => task.id === payload.id);
    state.list[oldTaskIndex] = payload;
  });

  /**
   * Update task
   */
  builder.addCase(updateTaskAction, (state, { payload }) => {
    if (!payload) return;

    const oldTaskIndex = state.list.findIndex(task => task.id === payload.id);
    state.list[oldTaskIndex] = payload;
    state.selected = payload;
  });

  /**
   * Update task status
   */
  builder.addCase(updateTaskStatusAction, (state, { payload }) => {
    if (!payload) return;

    const oldTaskIndex = state.list.findIndex(task => task.id === payload.id);
    state.list[oldTaskIndex].status = payload.status;

    if (state.selected) {
      state.selected.status = payload.status;
    }
  });

  /**
   * Create task
   */
  builder.addCase(createTaskAction, (state, { payload }) => {
    if (!payload) return;

    const oldTaskIndex = state.list.findIndex(task => task.id === payload.id);
    if (oldTaskIndex !== -1) {
      state.list[oldTaskIndex] = payload;
    } else {
      state.list = [...state.list, payload];
    }

    state.selected = payload;
  });

  /**
   * Init onCreate task
   */
  builder.addCase(initOnCreateTaskAction, (state, { payload }) => {
    if (!payload) return;

    state.selected = {
      id: 0,
      user: "0",
      title: "",
      subTitle: "",
      description: "",
      status: payload,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      threadId: "",
      read: true,
      planeIssueId: null,
      messages: [
        {
          id: 0,
          task: 0,
          content: "Hello, you would like a design ? How can I assist you ?",
          profile: 0,
          role: "assistant",
          created_at: new Date().toISOString(),
          threadId: "",
        },
      ],
    };
  });

  /**
   * Update onCreate task
   */
  builder.addCase(updateOnCreateTaskAction, (state, { payload }) => {
    if (!payload || !state.selected) return;

    state.selected.title = payload.title ?? state.selected.title;
    state.selected.subTitle = payload.subTitle ?? state.selected.subTitle;
    state.selected.description = payload.description ?? state.selected.description;
    state.selected.threadId = payload.threadId ?? state.selected.threadId;
    state.selected.status = payload.status ?? state.selected.status;
  });

  /**
   * Get messages of task
   */
  builder.addCase(getTaskMessagesAction, (state, { payload }) => {
    if (!payload || !state.selected) return;

    state.selected.messages = payload;
  });

  /**
   * Post message in task
   */
  builder.addCase(postTaskMessageAction, (state, { payload }) => {
    if (!payload || !state.selected || typeof payload === "boolean") return;
    if (payload.task !== state.selected.id) return;

    state.selected.messages = [...(state.selected.messages ?? []), payload];
  });

  /**
   * Clear task
   */
  builder.addCase(clearTaskAction, (state, { payload }) => {
    if (!payload) return;

    state.selected = null;
  });

  /**
   * On receive task message
   */
  builder.addCase(receiveTaskMessageAction, (state, { payload }) => {
    if (!payload || !state.selected) return;

    state.selected.messages = [...(state.selected.messages ?? []), payload];
  });

  /**
   * Delete task
   */
  builder.addCase(deleteTaskAction, (state, { payload }) => {
    if (!payload) return;

    if (state.selected && state.selected.id === payload.id) {
      state.selected = null;
    }

    const existedTaskIndex = state.list.findIndex(task => task.id === payload.id);
    if (existedTaskIndex !== -1) {
      const newTaskList = state.list.filter(task => task.id !== payload.id);
      state.list = newTaskList;
    }
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    state.list = [];
    state.selected = null;
  });
});
