import { resetPasswordAction } from "@core/usecases/authentication/reset-password/resetPasswordAction";
import { askResetPasswordAction } from "@core/usecases/authentication/ask-reset-password/askResetPasswordAction";
import { signinAction } from "@core/usecases/authentication/signin/signinAction";
import { signupAction } from "@core/usecases/authentication/signup/signupAction";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { createReducer } from "@reduxjs/toolkit";
import { AppState } from "@store/appState";

const initialState: AppState["authentication"] = {
  email: null,
  userUUID: null,
};

export const authenticationReducer = createReducer<AppState["authentication"]>(initialState, builder => {
  /**
   * Sign in
   */
  builder.addCase(signinAction, (state, action) => {
    if (!action.payload) return;

    state.email = action.payload.email;
    state.userUUID = action.payload.userUUID;
  });

  /**
   * Sign up
   */
  builder.addCase(signupAction, (state, action) => {
    if (!action.payload) return;

    state.email = action.payload.email;
    state.userUUID = action.payload.userUUID;
  });

  /**
   * askResetPasswordAction
   */
  builder.addCase(askResetPasswordAction, () => {
    return;
  });

  /**
   * resetPasswordAction
   */
  builder.addCase(resetPasswordAction, (state, action) => {
    if (!action.payload) return;

    state.email = action.payload.email;
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    state.email = null;
    state.userUUID = null;
  });
});
