import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import { Toaster } from "@app/components/ui/toaster";
import Maintenance from "@app/pages/public/maintenance/Maintenance";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { updateMaintenance } from "@core/usecases/app/update-maintenance/updateMaintenance";

const RootLayout: React.FC = () => {
  const app = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateMaintenance());
  }, [app.maintenance, dispatch]);

  if (app.maintenance) {
    return <Maintenance />;
  }

  return (
    <>
      <Outlet />
      <Toaster />
      <Analytics />
    </>
  );
};

export default RootLayout;
