import { AppThunk } from "@store/reduxStore";
import { getTaskMessagesAction } from "./getTaskMessagesAction";
import { Task, TaskRoleMessage } from "@core/gateways/task/taskGateway";

export const getTaskMessages =
  (task: Task, roles?: TaskRoleMessage[]): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.getTaskMessages(task, roles);
    dispatch(getTaskMessagesAction(query));
  };
