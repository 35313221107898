import { AppThunk } from "@store/reduxStore";
import { updateProfileAction } from "./updateProfileAction";
import { UpdateProfileType } from "@core/gateways/profile/profileGateway";

export const updateProfile =
  (newProfile: UpdateProfileType): AppThunk =>
  async (dispatch, _, { profileGateway }) => {
    const query = await profileGateway.updateProfile(newProfile);
    dispatch(updateProfileAction(query));
  };
