/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";
import { cn } from "@lib/utils";
import { Label } from "@app/components/ui/label";
import { useAppDispatch } from "@store/reduxStore";
import { Email } from "@core/types/authentication/email";
import { Button } from "@app/components/ui/button";
import { Icons } from "@app/components/ui/icon";
import { Input } from "@app/components/ui/input";
import { ROOT_DNS } from "@configs/env";
import { useNavigate } from "react-router-dom";
import { useToast } from "@app/components/ui/use-toast";
import { askResetPassword } from "@core/usecases/authentication/ask-reset-password/askResetPassword";
import { indexRoutes } from "@app/routes/urls";

interface Props {
  className?: string;
}

const AskResetPasswordForm: React.FC<Props> = ({ className, ...props }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });

  const onSubmit = async () => {
    if (!Email.isValid(user.email)) {
      toast({
        title: "⛔️ Invalid email",
        description: "Please check your email syntaxe",
      });
      return;
    }

    setIsLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(askResetPassword(Email.fromString(user.email)));
      navigate(indexRoutes.LOGIN);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <div>
        <div className="grid gap-2">
          <div className="grid gap-1 mb-4">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder={`city@${ROOT_DNS}`}
              type="email"
              value={user.email}
              onChange={e => setUser({ ...user, email: e.target.value })}
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <Button onClick={() => onSubmit()} disabled={isLoading}>
            {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
            Send reset password link
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AskResetPasswordForm;
