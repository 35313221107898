/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import { cn } from "@lib/utils";
import { Label } from "@app/components/ui/label";
import { useAppDispatch } from "@store/reduxStore";
import { Button } from "@app/components/ui/button";
import { Icons } from "@app/components/ui/icon";
import { Input } from "@app/components/ui/input";
import { useNavigate } from "react-router-dom";
import { Password } from "@core/types/authentication/password";
import { useToast } from "@app/components/ui/use-toast";
import { Session } from "@supabase/supabase-js";
import { resetPassword } from "@core/usecases/authentication/reset-password/resetPassword";
import { indexRoutes } from "@app/routes/urls";
import { supabase } from "@services/supabase";

interface Props {
  className?: string;
}

const ResetPasswordForm: React.FC<Props> = ({ className, ...props }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState<Session>();
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      setSession({
        access_token: hashParams.get("access_token") ?? "",
        refresh_token: hashParams.get("refresh_token") ?? "",
        expires_in: parseInt(hashParams.get("expires_in") ?? "0") ?? 0,
        token_type: hashParams.get("token_type") ?? "",
        user: {
          id: hashParams.get("user_id") ?? "",
          app_metadata: {},
          user_metadata: {},
          aud: "",
          created_at: hashParams.get("created_at") ?? "",
        },
      });
    }
  }, []);

  useEffect(() => {
    const getSessionWithTokens = async () => {
      if (session) {
        const { error } = await supabase.auth.setSession({
          access_token: session.access_token,
          refresh_token: session.refresh_token,
        });

        if (error) {
          toast({ title: "⛔️ Error", description: `Error signing in: ${error.message}` });
        }
      }
    };

    if (session) {
      getSessionWithTokens().catch(e => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const onSubmit = async () => {
    if (user.password !== user.confirmPassword || session?.access_token === "") {
      toast({
        title: "⛔️ Invalid password",
        description: "Please check your password",
      });
      return;
    }

    setIsLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(resetPassword(Password.fromString(user.password), session!));
      navigate(indexRoutes.AUTH);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <div className="grid gap-2">
        <div className="grid gap-1 mb-2">
          <Label className="sr-only" htmlFor="password">
            Password
          </Label>
          <Input
            id="password"
            placeholder="*********"
            type="password"
            value={user.password}
            onChange={e => setUser({ ...user, password: e.target.value })}
            autoCapitalize="none"
            disabled={isLoading}
          />
        </div>
        <div className="grid gap-1 mb-2">
          <Label className="sr-only" htmlFor="password">
            Confirm password
          </Label>
          <Input
            id="confirm_password"
            placeholder="*********"
            type="password"
            value={user.confirmPassword}
            onChange={e => setUser({ ...user, confirmPassword: e.target.value })}
            autoCapitalize="none"
            autoCorrect="off"
            disabled={isLoading}
          />
        </div>
        <Button disabled={isLoading} onClick={() => onSubmit()}>
          {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
          Reset password
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
