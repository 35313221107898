import { AppThunk } from "@store/reduxStore";
import { resetPasswordAction } from "./resetPasswordAction";
import { Password } from "@core/types/authentication/password";
import { Session } from "@supabase/supabase-js";

/**
 * Async resetPassword
 *
 * @description Will be executed in ResetPassword screen
 * @param password - Password of user
 * @param session - Session
 * @returns Object with: success: true or false and message
 */
export const resetPassword =
  (password: Password, session: Session): AppThunk =>
  async (dispatch, _, { authenticationGateway }) => {
    const query = await authenticationGateway.resetPassword(password, session);
    dispatch(resetPasswordAction(query));
  };
