export const userRoutes = {
  SUBSCRIPTION: "/user/subscription",
  BASENAME: "/user",
  DASHBOARD: "/user",
  SETTINGS: "/user/settings",
  PROFILE: "/user/settings",
  ACCOUNT: "/user/settings/account",
  APPEARANCE: "/user/settings/appearance",
  NOTIFICATIONS: "/user/settings/notifications",
};
