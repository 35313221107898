import { AuthenticationGateway, SigninResponse } from "@core/gateways/authentication/authenticationGateway";
import { Email } from "@core/types/authentication/email";
import { Password } from "@core/types/authentication/password";
import { profileAdminMock, profileUserMock, profileWorkerMock } from "@root/__mocks__/profile/profileMock";

export class LocalAuthenticationGateway implements AuthenticationGateway {
  protected userEmail = "user@company.com";
  protected adminEmail = "admin@company.com";
  protected workerEmail = "worker@company.com";
  protected goodPassword = "123456";

  protected selectProfileByEmail(email: Email) {
    if (email.toString() === this.userEmail) {
      return profileUserMock;
    } else if (email.toString() === this.adminEmail) {
      return profileAdminMock;
    } else if (email.toString() === this.workerEmail) {
      return profileWorkerMock;
    }

    return null;
  }

  signup(email: Email, password: Password): Promise<SigninResponse | null> {
    console.log(email, password);
    return new Promise((resolve, reject) => {
      if (password.toString() === this.goodPassword) {
        const profile = this.selectProfileByEmail(email);

        if (!profile) {
          reject(new Error("Bad credentials"));
        }

        resolve({
          userUUID: "azed",
          email: email.toString(),
          profile,
        });
      } else {
        reject(new Error("Bad credentials"));
      }
    });
  }

  async signin(email: Email, password: Password): Promise<SigninResponse | null> {
    return new Promise((resolve, reject) => {
      if (password.toString() === this.goodPassword) {
        const profile = this.selectProfileByEmail(email);

        if (!profile) {
          reject(new Error("Bad credentials"));
        }

        resolve({
          userUUID: "azed",
          email: email.toString(),
          profile,
        });
      } else {
        reject(new Error("Bad credentials"));
      }
    });
  }

  async askResetPassword(): Promise<void> {
    return new Promise(resolve => resolve());
  }

  async resetPassword(): Promise<SigninResponse | null> {
    return new Promise(resolve => resolve(null));
  }

  async signout(): Promise<boolean> {
    return new Promise(resolve => resolve(true));
  }
}
