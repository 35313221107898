import { languagesList } from "@configs/language";
import { Profile } from "@core/gateways/profile/profileGateway";
import { planMock } from "../plan/planMock";

export const profileUserMock: Profile = {
  id: 1,
  username: "eric",
  email: "eric@dezzzign.com",
  displayName: "Eric Choi",
  firstName: "Eric",
  bio: "I am a user",
  lastName: "Choi",
  role: "USER",
  language: languagesList[0],
  notifications: {
    communication_emails: true,
    security_emails: false,
    push: true,
  },
  subscription: {
    id: 1,
    user: "1",
    status: "ACTIVE",
    plan: planMock,
    updated_at: new Date().toLocaleTimeString(),
    created_at: new Date().toLocaleTimeString(),
  },
};

export const profileWorkerMock: Profile = {
  id: 1,
  username: "eric",
  bio: "I am a worker",
  email: "eric@dezzzign.com",
  displayName: "Eric Choi",
  firstName: "Eric",
  lastName: "Choi",
  role: "WORKER",
  language: languagesList[0],
  notifications: {
    communication_emails: true,
    security_emails: false,
    push: true,
  },
  subscription: null,
};

export const profileAdminMock: Profile = {
  id: 1,
  email: "eric@dezzzign.com",
  bio: "I am an admin",
  displayName: "Eric Choi",
  username: "eric",
  firstName: "Eric",
  lastName: "Choi",
  role: "ADMIN",
  language: languagesList[0],
  notifications: {
    communication_emails: true,
    security_emails: false,
    push: true,
  },
  subscription: null,
};
