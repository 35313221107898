import React, { useState } from "react";
import { Task } from "@core/gateways/task/taskGateway";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "@app/components/ui/badge";
import { cn } from "@lib/utils";
import { TrashIcon } from "@radix-ui/react-icons";
import { getTask } from "@core/usecases/task/get-task/getTask";
import { useAppDispatch } from "@store/reduxStore";
import { Button } from "@app/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@app/components/ui/dialog";
import { deleteTask } from "@core/usecases/task/delete-task/deleteTask";

interface TaskCardProps {
  item: Task;
  itemSelected?: Task | null;
}

export const TaskCard: React.FC<TaskCardProps> = ({ item, itemSelected }) => {
  const dispatch = useAppDispatch();

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  return (
    <button
      key={item.id}
      onMouseLeave={() => setShowDeleteButton(false)}
      onMouseEnter={() => setShowDeleteButton(true)}
      className={cn(
        "relative flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
        itemSelected?.threadId === item.threadId && "bg-muted"
      )}
      onClick={() => dispatch(getTask(item))}
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <div className="font-semibold">{item.title}</div>
            {!item.read && <span className="flex h-2 w-2 rounded-full bg-blue-600" />}
          </div>
          <div
            className={cn(
              "ml-auto text-xs text-right",
              itemSelected?.threadId === item.threadId ? "text-foreground" : "text-muted-foreground"
            )}
          >
            {formatDistanceToNow(new Date(item.updated_at), {
              addSuffix: true,
            })}
          </div>
        </div>
        <div className="text-xs font-medium">{item.subTitle}</div>
      </div>
      <div className="flex items-center justify-between w-full">
        <Badge variant="secondary">{item.status}</Badge>
        <Dialog>
          <DialogTrigger asChild className={`${showDeleteButton ? "" : "hidden"}`} onClick={e => e.stopPropagation()}>
            <span className={`${showDeleteButton ? "" : "hidden"}`}>
              <TrashIcon className="h-4 w-4" />
            </span>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Delete task</DialogTitle>
              <DialogDescription>This action is not reversible</DialogDescription>
            </DialogHeader>
            <div className="flex flex-col-reverse md:flex-row justify-end gap-4 py-4">
              <Button>Cancel</Button>
              <Button
                variant="destructive"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(deleteTask(item));
                }}
              >
                Delete
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </button>
  );
};
