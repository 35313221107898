import { AppThunk } from "@store/reduxStore";
import { Task, TaskStatus } from "@core/gateways/task/taskGateway";
import { updateTaskStatusAction } from "./updateTaskStatusAction";

export const updateTaskStatus =
  (task: Task, status: TaskStatus): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.updateTaskStatus(task, status);

    dispatch(updateTaskStatusAction(query));
  };
