import React, { createContext, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { supabase } from "@services/supabase";
import { websocketInterceptor } from "@core/usecases/websocket-interceptor/websocketInterceptor";
import { getProfile } from "@core/usecases/profile/get-profile/getProfile";

interface WebsocketProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WebsocketProviderState {}

const initialState: WebsocketProviderState = {};

const WebsocketProviderContext = createContext<WebsocketProviderState>(initialState);

export function WebsocketProvider({ children }: WebsocketProviderProps) {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profile);
  const auth = useAppSelector(state => state.authentication);

  useEffect(() => {
    const socket = supabase
      .channel(`*`)
      .on("postgres_changes", { event: "*", schema: "public", filter: `user=eq.${auth.userUUID}` }, payload => {
        if (payload.errors) {
          console.log(payload.errors);
          return;
        }

        dispatch(websocketInterceptor(payload, payload.eventType));
      })
      .subscribe(() => {
        if (!auth.email || !profile?.id) return;

        dispatch(getProfile());
      });

    return () => {
      void socket?.unsubscribe();
    };
  }, [auth, dispatch, profile.id]);

  const contextValue = {};

  return <WebsocketProviderContext.Provider value={contextValue}>{children}</WebsocketProviderContext.Provider>;
}

export const useWebsocketContext = () => {
  const context = useContext(WebsocketProviderContext);

  if (context === undefined) throw new Error("useWebsocketContext was used outside of its Provider");

  return context;
};
