import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { updateMaintenance } from "@core/usecases/app/update-maintenance/updateMaintenance";
import { AppState } from "@store/appState";
import { AppDispatch } from "@store/reduxStore";
import { Agent } from "./Agent";

export class MaintenanceAgent implements Agent {
  appState: AppState;
  dispatch: AppDispatch;
  payload: RealtimePostgresChangesPayload<Record<string, unknown>>;

  constructor(
    appState: AppState,
    dispatch: AppDispatch,
    payload: RealtimePostgresChangesPayload<Record<string, unknown>>
  ) {
    this.appState = appState;
    this.dispatch = dispatch;
    this.payload = payload;
  }

  manage() {
    switch (this.payload.eventType) {
      case "DELETE":
        this.delete();
        break;
      case "INSERT":
        this.insert();
        break;
      case "UPDATE":
        this.update();
        break;
      default:
        break;
    }
  }

  delete() {
    console.log("Maintenance deleted");
  }

  insert() {
    console.log("Maintenance inserted");
  }

  update() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (!this.payload.new.id) return;

    this.dispatch(updateMaintenance());
  }
}
