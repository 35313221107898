import React from "react";
import { RouterProvider } from "react-router-dom";
import persistStore from "redux-persist/es/persistStore";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate as PersistProvider } from "redux-persist/es/integration/react";
import { ThemeProvider } from "@app/contexts/theme-provider";
import { initReduxStore } from "@store/reduxStore";
import { gatewaySelectors } from "@adapters/gateways/gatewaySelectors";
import { router } from "./routes";
import "@translation/initTranslation";

/**
 * Store
 */
export const store = initReduxStore(gatewaySelectors);

/**
 * Store perist in memory
 */
export const persistor = persistStore(store);

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <PersistProvider persistor={persistor}>
        <ThemeProvider>
          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistProvider>
    </ReduxProvider>
  );
};

export default App;
