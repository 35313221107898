import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardDescription, CardHeader, CardTitle } from "@components/ui/card";
import { useAppSelector } from "@store/reduxStore";
import { indexRoutes } from "@app/routes/urls";
import AskResetPasswordForm from "@app/components/auth/auth-form/ask-reset-password-form";

const AskResetPassword: React.FC = () => {
  const profile = useAppSelector(state => state.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.role !== "GUEST") {
      navigate(indexRoutes.AUTH);
    }
  }, [profile, navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-zinc-900">
      <Card className="min-w-[380px] max-w-[520px] p-8">
        <CardHeader className="text-center">
          <CardTitle className="lg:text-4xl text-2xl">Forgot password ?</CardTitle>
          <CardDescription>
            Enter your email address below and we&apos;ll send you a link to reset your password.
          </CardDescription>
        </CardHeader>
        <AskResetPasswordForm />
      </Card>
    </div>
  );
};

export default AskResetPassword;
