import { AppThunk } from "@store/reduxStore";
import { askResetPasswordAction } from "./askResetPasswordAction";
import { Email } from "@core/types/authentication/email";

/**
 * Async askResetPassword
 *
 * @description Will be executed in askResetPassword screen
 * @param email - Email of user
 * @returns Object with: success: true or false and message
 */
export const askResetPassword =
  (email: Email): AppThunk =>
  async (dispatch, _, { authenticationGateway }) => {
    const query = await authenticationGateway.askResetPassword(email);
    dispatch(askResetPasswordAction(query));
  };
