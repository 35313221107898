import { Account, Profile, ProfileGateway, UpdateProfileType } from "@core/gateways/profile/profileGateway";

export class LocalProfileGateway implements ProfileGateway {
  async getProfile(): Promise<Profile & { uuid: string }> {
    try {
      return new Promise(() => {
        throw new Error("No session found");
      });
    } catch (error) {
      console.log(error);
      throw new Error("Error occured");
    }
  }

  async updateNotifications(notifications: Profile["notifications"]): Promise<Profile["notifications"] | null> {
    try {
      return new Promise(resolve => {
        resolve(notifications);
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateProfile(newProfile: UpdateProfileType): Promise<UpdateProfileType> {
    return new Promise(resolve => {
      resolve(newProfile);
    });
  }

  async updateAccount(account: Account): Promise<Account | null> {
    return new Promise(resolve => {
      resolve(account);
    });
  }
}
