// Sentry credentials
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN;
export const SENTRY_ORG = import.meta.env.VITE_SENTRY_ORG;
export const SENTRY_PROJECT = import.meta.env.VITE_SENTRY_PROJECT;

// Supabase credentials
export const SB_URL = import.meta.env.VITE_SUPABASE_URL;
export const SB_KEY = import.meta.env.VITE_SUPABASE_KEY;

// App credentials
export const APP_NAME = import.meta.env.VITE_APP_NAME;
export const APP_URL = import.meta.env.VITE_APP_URL;
export const PERSISTED_KEY = import.meta.env.VITE_PERSISTED_KEY;
export const ROOT_DNS = import.meta.env.VITE_ROOT_DNS;

// App mode
export const MODE = import.meta.env.VITE_MODE;

// Stripe credentials
export const STRIPE_PK = import.meta.env.VITE_STRIPE_PK;
