import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { AppState } from "@store/appState";
import { AppDispatch } from "@store/reduxStore";
import { Agent } from "./Agent";
import { receiveTaskMessage } from "@core/usecases/task/receive-task-message/receiveTaskMessage";
import { Message } from "@core/gateways/task/taskGateway";

export class MessageAgent implements Agent {
  appState: AppState;
  dispatch: AppDispatch;
  payload: RealtimePostgresChangesPayload<Record<string, unknown>>;

  constructor(
    appState: AppState,
    dispatch: AppDispatch,
    payload: RealtimePostgresChangesPayload<Record<string, unknown>>
  ) {
    this.appState = appState;
    this.dispatch = dispatch;
    this.payload = payload;
  }

  manage() {
    switch (this.payload.eventType) {
      case "DELETE":
        this.delete();
        break;
      case "INSERT":
        this.insert();
        break;
      case "UPDATE":
        this.update();
        break;
      default:
        break;
    }
  }

  delete() {
    console.log("Deleted message");
  }

  insert() {
    const { selected } = this.appState.task;

    const data = this.payload.new as Message;
    console.log(
      { local: { id: selected?.id, threadId: selected?.threadId } },
      { payload: { threadId: data.threadId, id: data.id } }
    );
    const isEligible = data.threadId === selected?.threadId || data.id === selected?.id;

    if (isEligible && selected.status === "on_create") {
      this.dispatch(receiveTaskMessage(data));
    }
  }

  update() {
    console.log("Updated message");
  }
}
