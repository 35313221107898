import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@lib/utils";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@app/components/ui/resizable";
import { TooltipProvider } from "@app/components/ui/tooltip";
import { Separator } from "@app/components/ui/separator";
import favicon from "@app/assets/images/favicon.png";
import Nav from "@app/components/user/dashboard/nav";
import { ClipboardSignature, LogOut, Plus, Settings } from "lucide-react";
import { Button } from "@app/components/ui/button";
import { userRoutes } from "@app/routes/user/urls";
import { signout } from "@core/usecases/authentication/signout/signout";
import { clearTask } from "@core/usecases/task/clear-task/clearTask";
import { indexRoutes } from "@app/routes/urls";
import { initOnCreateTask } from "@core/usecases/task/init-oncreate-task/initOnCreateTask";
import { useToast } from "@app/components/ui/use-toast";

export const defaultLayout = [265, 440, 655];

const UserLayout: React.FC = () => {
  const navCollapsedSize = 4;
  const { pathname } = useLocation();
  const { toast } = useToast();

  const match = pathname.match(/\/user\/settings/);
  const settingsPath = match ? match[0] : null;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.task.selected);
  const profile = useAppSelector(state => state.profile);
  const taskList = useAppSelector(state => state.task.list);
  const unreadTaskCount = taskList.filter(e => !e.read).length.toString();

  const [isCollapsed, setIsCollapsed] = useState(false);

  if (profile.role !== "USER" || !profile.email) {
    return <Navigate to={indexRoutes.LOGIN} replace />;
  }

  if (profile.subscription?.status === "EXPIRED" || !profile.subscription) {
    return <Navigate to={userRoutes.SUBSCRIPTION} replace />;
  }

  const navigateAndCreate = () => {
    const notCompletedTask = taskList.filter(e => e.status !== "completed");
    if (notCompletedTask.length > 0) {
      toast({
        title: "🤨 You have an incomplete task",
        description: "Please complete the task before creating a new one",
      });
      return;
    }

    navigate(userRoutes.DASHBOARD);
    dispatch(clearTask(selected));
    dispatch(initOnCreateTask("on_create"));
  };

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
        }}
        className="items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          minSize={15}
          maxSize={20}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          className={cn(isCollapsed && "min-w-[50px] max-w-[55px] transition-all duration-300 ease-in-out")}
        >
          <div className={cn("flex h-[52px] items-center", isCollapsed ? "h-[52px] justify-center" : "px-2")}>
            <img
              onClick={() => navigate(userRoutes.DASHBOARD)}
              src={favicon}
              width={32}
              height={32}
              className="cursor-pointer rounded-md w-8 h-8"
            />
          </div>
          <Separator />
          <div className="p-2">
            {
              <Button className="w-full" onClick={() => navigateAndCreate()}>
                <Plus className={isCollapsed ? "" : ""} />
                <span className={isCollapsed ? "hidden" : "hidden md:inline-block"}> New Task</span>
              </Button>
            }
          </div>
          <Nav
            isCollapsed={isCollapsed}
            links={[
              {
                title: "Tasks",
                label: parseInt(unreadTaskCount) === 0 ? "" : unreadTaskCount,
                icon: ClipboardSignature,
                variant: pathname === userRoutes.DASHBOARD ? "secondary" : "ghost",
                action: () => navigate(userRoutes.DASHBOARD),
              },
              {
                title: "Settings",
                label: "",
                icon: Settings,
                variant: pathname === userRoutes.SETTINGS || settingsPath ? "secondary" : "ghost",
                action: () => navigate(userRoutes.SETTINGS),
              },
              {
                title: "Log out",
                label: "",
                icon: LogOut,
                variant: "ghost",
                action: () => dispatch(signout()),
              },
            ]}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <Outlet />
      </ResizablePanelGroup>
    </TooltipProvider>
  );
};

export default UserLayout;
