import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@app/components/ui/avatar";
import { Separator } from "@app/components/ui/separator";
import { format } from "date-fns";
import { Task } from "@core/gateways/task/taskGateway";
import CreateTaskSection from "@app/components/chat/create-task-section";
import ChatDisplay from "@app/components/chat/chat-display";
import { Button } from "@app/components/ui/button";
import { clearTask } from "@core/usecases/task/clear-task/clearTask";
import { useAppDispatch } from "@store/reduxStore";

interface TaskDisplayProps {
  task: Task | null;
}

const TaskChatDisplay: React.FC<TaskDisplayProps> = ({ task }) => {
  const dispatch = useAppDispatch();

  if (task?.status === "on_create" || task?.status === "confirmed") {
    return (
      <div className="flex h-screen relative">
        <Button className="z-40 md:hidden absolute left-4 top-4" onClick={() => dispatch(clearTask(task))}>
          X
        </Button>
        <div className="flex flex-1 flex-col">
          <div className="flex items-start p-4">
            <div className="flex items-start gap-4 text-sm">
              <Avatar>
                <AvatarImage alt="D" />
              </Avatar>
              <div className="grid gap-1">
                <div className="font-semibold">{task.title.length > 0 ? task.title : "New task"}</div>
              </div>
            </div>
          </div>
          <Separator />
          <CreateTaskSection existedTask={task} />
        </div>
      </div>
    );
  }

  if (task?.id) {
    return (
      <div className="flex h-screen relative">
        <Button className="z-40 md:hidden absolute left-4 top-4" onClick={() => dispatch(clearTask(task))}>
          X
        </Button>
        <div className="flex flex-1 flex-col">
          <div className="flex items-start p-4">
            <div className="flex items-start gap-4 text-sm">
              <Avatar>
                <AvatarImage alt={task.title} />
                <AvatarFallback>
                  {task.title
                    .split(" ")
                    .map(chunk => chunk[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <div className="grid gap-1">
                <div className="font-semibold">{task.title}</div>
                <div className="line-clamp-1 text-xs">{task.subTitle}</div>
                {task.updated_at && (
                  <div className="line-clamp-1 text-xs">{format(new Date(task.updated_at), "PPpp")}</div>
                )}
              </div>
            </div>
          </div>
          <Separator />
          <ChatDisplay selected={task} />
        </div>
      </div>
    );
  }

  return <div className="p-8 text-center text-muted-foreground hidden md:flex">No message selected</div>;
};

export default TaskChatDisplay;
