import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "@store/reduxStore";
import Header from "@app/components/admin/header";

const AdminLayout: React.FC = () => {
  const role = useAppSelector(state => state.profile.role);
  const { pathname } = useLocation();

  if (role !== "ADMIN") {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Header path={pathname} />
      <Outlet />
    </>
  );
};

export default AdminLayout;
