import React from "react";
import imgUrl from "@app/assets/images/logo.png";

const Maintenance: React.FC = () => {
  return (
    <main className="h-screen w-screen text-smooth">
      <div className="h-full flex flex-col items-center justify-center">
        <img src={imgUrl} alt="Logo" width={300} height={113} className="mb-10" />
        <h1 className="text-2xl mb-2">MAINTENANCE EN COURS</h1>
        <h2 className="text-xl">Revenez plus tard !</h2>
      </div>
    </main>
  );
};

export default Maintenance;
