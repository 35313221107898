import { useAppSelector } from "@store/reduxStore";
import { createContext, useContext, useEffect } from "react";

interface ThemeProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ThemeProviderState {}

const initialState: ThemeProviderState = {};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  const appTheme = useAppSelector(state => state.app.theme);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark");

    if (appTheme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(appTheme);
  }, [appTheme]);

  const value = {};

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
