import { MODE } from "@configs/env";
import { AppGateway, LanguageType, ThemeType } from "@core/gateways/app/appGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseAppGateway implements AppGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async updateMaintenance(): Promise<boolean> {
    try {
      const { data } = await this.cloud.from("maintenance").select("id, value").eq("mode", MODE).single();

      return data ? data.value : false;
    } catch (error) {
      return false;
    }
  }

  async updateLanguage(language: LanguageType): Promise<LanguageType> {
    return new Promise(resolve => {
      this.toast.show("Language updated", `Language has been updated to ${language.title}`);
      resolve(language);
    });
  }

  async updateTheme(theme: ThemeType): Promise<ThemeType> {
    return new Promise(resolve => {
      this.toast.show("Theme updated", `Theme has been updated to ${theme}`);
      resolve(theme);
    });
  }

  async updateDownloadRedirection(value: boolean): Promise<boolean> {
    return new Promise(resolve => {
      resolve(value);
    });
  }
}
