/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button } from "@app/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@app/components/ui/command";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@app/components/ui/form";
import { Input } from "@app/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@app/components/ui/popover";
import { useToast } from "@app/components/ui/use-toast";
import { languagesList } from "@configs/language";
import { updateAccount } from "@core/usecases/profile/update-account/updateAccount";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@lib/utils";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { useState } from "react";
import { Icons } from "@app/components/ui/icon";

const accountFormSchema = z.object({
  displayName: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  language: z.string({
    required_error: "Please select a language.",
  }),
});

type AccountFormValues = z.infer<typeof accountFormSchema>;

export function AccountForm() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profile);
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues: Partial<AccountFormValues> = {
    displayName: `${profile?.displayName}`,
    language: profile?.language.value,
  };

  const form = useForm<AccountFormValues>({
    resolver: zodResolver(accountFormSchema),
    defaultValues,
  });

  const onSubmit = (data: AccountFormValues) => {
    console.log({ data });
  };

  const handleTest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      form.handleSubmit(onSubmit);
      const selectedLanguage = languagesList.filter(e => e.value === form.getValues("language"))[0];

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        updateAccount({
          displayName: form.getValues("displayName"),
          language: selectedLanguage,
        })
      );

      toast({
        title: "Account updated",
        description: "Your account information has been updated.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleTest} className="space-y-8">
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Your name" {...field} />
              </FormControl>
              <FormDescription>This is the name that will be displayed on your profile and in emails.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="language"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Language</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn("w-[200px] justify-between", !field.value && "text-muted-foreground")}
                    >
                      {field.value
                        ? languagesList.find(language => language.value === field.value)?.title
                        : "Select language"}
                      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                  <Command>
                    <CommandEmpty>No language found.</CommandEmpty>
                    <CommandGroup>
                      {languagesList.map(language => (
                        <CommandItem
                          value={language.value}
                          key={language.value}
                          onSelect={() => {
                            form.setValue("language", language.value);
                          }}
                        >
                          <CheckIcon
                            className={cn("mr-2 h-4 w-4", language.value === field.value ? "opacity-100" : "opacity-0")}
                          />
                          {language.title}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormDescription>This is the language that will be used in the dashboard.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
          Update account
        </Button>
      </form>
    </Form>
  );
}
