import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <div>PrivacyPolicy</div>
    </>
  );
};

export default PrivacyPolicy;
