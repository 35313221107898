import React, { useEffect, useState } from "react";
import { Search } from "lucide-react";
import { Separator } from "@app/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@app/components/ui/tabs";
import { Input } from "@app/components/ui/input";
import { ResizableHandle, ResizablePanel } from "@app/components/ui/resizable";
import TaskChatDisplay from "./task-chat-display";
import TaskList from "./task-list";
import { Task as ITask } from "@core/gateways/task/taskGateway";
import { useAppSelector } from "@store/reduxStore";

interface TaskProps {
  tasks: ITask[];
  defaultLayout: number[];
  navCollapsedSize: number;
}

const Task: React.FC<TaskProps> = ({ tasks, defaultLayout }) => {
  const task = useAppSelector(state => state.task.selected);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ITask[]>([]);

  useEffect(() => {
    const filter = tasks.filter(
      task =>
        task.title.toLowerCase().includes(search.toLowerCase()) ||
        task.description.toLowerCase().includes(search.toLowerCase())
    );

    setSearchResult(filter);
  }, [search, tasks]);

  return (
    <>
      <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
        <Tabs defaultValue="all" className="flex flex-col h-screen">
          <div className="flex items-center px-4 py-2">
            <h1 className="text-xl font-bold">Task</h1>
            <TabsList className="ml-auto">
              <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">
                All
              </TabsTrigger>
              <TabsTrigger value="completed" className="text-zinc-600 dark:text-zinc-200">
                Completed
              </TabsTrigger>
            </TabsList>
          </div>
          <Separator />
          <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input onChange={e => setSearch(e.target.value)} placeholder="Search" className="pl-8" />
            </div>
          </div>
          <div className="overflow-y-scroll">
            {searchResult.length >= 1 ? (
              <>
                <TabsContent value="all" className="m-0">
                  <TaskList items={searchResult} />
                </TabsContent>
                <TabsContent value="completed" className="m-0">
                  <TaskList items={searchResult.filter(task => task.status === "completed")} />
                </TabsContent>
              </>
            ) : (
              <>
                <TabsContent value="all" className="m-0">
                  <TaskList items={tasks} />
                </TabsContent>
                <TabsContent value="completed" className="m-0">
                  <TaskList items={tasks.filter(task => task.status === "completed")} />
                </TabsContent>
              </>
            )}
          </div>
        </Tabs>
      </ResizablePanel>
      <ResizableHandle withHandle className="hidden md:flex" />
      <ResizablePanel
        defaultSize={defaultLayout[2]}
        className="z-40 md:z-0 absolute md:relative w-full md:w-auto bg-white dark:bg-neutral-950"
      >
        <TaskChatDisplay task={task} />
      </ResizablePanel>
    </>
  );
};

export default Task;
