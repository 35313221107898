import React from "react";
import favicon from "@app/assets/images/favicon.png";
import { adminRoutes } from "@app/routes/admin/urls";
import { useNavigate } from "react-router-dom";
import { Badge } from "../ui/badge";
import { ModeToggle } from "../ui/mode-toggle";

interface Props {
  path: string;
}

const Header: React.FC<Props> = ({ path }) => {
  const navigate = useNavigate();

  return (
    <div className="sticky top-0 px-8 py-4 flex justify-between gap-4 bg-background z-50">
      <div className="flex gap-4 items-center">
        <img src={favicon} width={32} height={32} className="rounded-md w-8 h-8" />
        <Badge
          variant={path === adminRoutes.DASHBOARD ? "default" : "outline"}
          className="cursor-pointer"
          onClick={() => navigate(adminRoutes.DASHBOARD)}
          aria-disabled={path === adminRoutes.DASHBOARD}
        >
          Dashboard
        </Badge>
        <Badge
          variant={path === adminRoutes.SETTINGS ? "default" : "outline"}
          className="cursor-pointer"
          onClick={() => navigate(adminRoutes.SETTINGS)}
        >
          Settings
        </Badge>
      </div>
      <ModeToggle />
    </div>
  );
};

export default Header;
