import React from "react";
import { useAppSelector } from "@store/reduxStore";
import { Navigate, useLocation } from "react-router-dom";
import { indexRoutes } from "@app/routes/urls";
import { userRoutes } from "@app/routes/user/urls";
import { adminRoutes } from "@app/routes/admin/urls";
import { workerRoutes } from "@app/routes/worker/urls";

const RootLayout: React.FC = () => {
  const email = useAppSelector(state => state.authentication.email);
  const { role, subscription } = useAppSelector(state => state.profile);
  const location = useLocation();

  if (!email) {
    return <Navigate to={indexRoutes.LOGIN} replace state={{ from: location }} />;
  }

  if (role === "ADMIN") {
    return <Navigate to={adminRoutes.DASHBOARD} replace />;
  }

  if (role === "USER") {
    if (subscription?.status !== "EXPIRED") {
      return <Navigate to={userRoutes.DASHBOARD} replace />;
    }

    return <Navigate to={userRoutes.SUBSCRIPTION} replace />;
  }

  if (role === "WORKER") {
    return <Navigate to={workerRoutes.DASHBOARD} replace />;
  }

  return <Navigate to={indexRoutes.ALL} replace />;
};

export default RootLayout;
