/* eslint-disable no-case-declarations */
import { signout } from "@core/usecases/authentication/signout/signout";
import { MaintenanceAgent } from "@services/agent/MaintenanceAgent";
import { MessageAgent } from "@services/agent/MessageAgent";
import { SubscriptionAgent } from "@services/agent/SubscriptionAgent";
import { AppState } from "@store/appState";
import { AppDispatch } from "@store/reduxStore";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";

interface WebsocketManager {
  appState: AppState;
  dispatch: AppDispatch;
  payload: RealtimePostgresChangesPayload<Record<string, unknown>>;
}

export const websocketManager = async ({ appState, dispatch, payload }: WebsocketManager) => {
  if (!appState.profile) {
    dispatch(signout());
  }

  switch (payload.table) {
    case "subscription_user":
      const subscriptionAgent = new SubscriptionAgent(appState, dispatch, payload);
      await subscriptionAgent.manage();
      break;
    case "maintenance":
      const maintenanceAgent = new MaintenanceAgent(appState, dispatch, payload);
      maintenanceAgent.manage();
      break;
    case "message":
      const messageAgent = new MessageAgent(appState, dispatch, payload);
      messageAgent.manage();
      break;

    default:
      break;
  }
};
