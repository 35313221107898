import { AppThunk } from "@store/reduxStore";
import { getProfileAction } from "./getProfileAction";

export const getProfile =
  (): AppThunk =>
  async (dispatch, getState, { profileGateway }) => {
    const profile = getState().profile;
    if (!profile?.email) return;

    const query = await profileGateway.getProfile();
    dispatch(getProfileAction(query));
  };
