import { AppThunk } from "@store/reduxStore";
import { Email } from "@core/types/authentication/email";
import { signupAction } from "./signupAction";
import { Password } from "@core/types/authentication/password";

export const signup =
  (email: Email, password: Password): AppThunk =>
  async (dispatch, _, { authenticationGateway }) => {
    const query = await authenticationGateway.signup(email, password);
    dispatch(signupAction(query));
  };
