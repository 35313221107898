import { AppThunk } from "@store/reduxStore";
import { CreateTask } from "@core/gateways/task/taskGateway";
import { createTaskAction } from "./createTaskAction";

export const createTask =
  (task: CreateTask): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.createTask(task);
    dispatch(createTaskAction(query));
  };
