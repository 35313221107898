import { AppThunk } from "@store/reduxStore";
import { updateMaintenanceAction } from "./updateMaintenanceAction";

/**
 * Async updateMaintenance
 *
 * @returns boolean if success or not
 */
export const updateMaintenance =
  (): AppThunk =>
  async (dispatch, _, { appGateway }) => {
    const query = await appGateway.updateMaintenance();
    dispatch(updateMaintenanceAction(query));
  };
