import { AppThunk } from "@store/reduxStore";
import { Task } from "@core/gateways/task/taskGateway";
import { clearTaskAction } from "./clearTaskAction";

export const clearTask =
  (task: Task | null): AppThunk =>
  (dispatch, getState) => {
    const profile = getState().profile;
    if (!profile?.email || !task) return;

    dispatch(clearTaskAction(task));
  };
