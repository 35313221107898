export class Password {
  static MINIMUM_LENGTH = 6;

  password = "";

  constructor(password: string) {
    this.password = password;
  }

  static isValid(password = ""): boolean {
    return password.length >= this.MINIMUM_LENGTH;
  }

  toString(): string {
    return this.password;
  }

  static fromString(password: string): Password {
    if (this.isValid(password)) {
      return new Password(password);
    }

    throw new Error("Entrer un mot de passe valide.");
  }
}
