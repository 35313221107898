import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/ui/button";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@components/ui/card";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-zinc-900">
      <Card className="w-[420px]">
        <CardHeader className="text-center">
          <CardTitle className="lg:text-7xl text-4xl">404</CardTitle>
          <CardDescription>The page you&apos;re looking for doesn&apos;t exist.</CardDescription>
        </CardHeader>
        <CardFooter className="flex justify-center">
          <Button asChild>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default NotFound;
