import { indexRoutes } from "@app/routes/urls";
import { APP_URL } from "@configs/env";
import { languagesList } from "@configs/language";
import { AuthenticationGateway, SigninResponse } from "@core/gateways/authentication/authenticationGateway";
import { Profile } from "@core/gateways/profile/profileGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Email } from "@core/types/authentication/email";
import { Password } from "@core/types/authentication/password";
import { Supabase } from "@services/supabase";
import { Session, User } from "@supabase/supabase-js";

export class SupabaseAuthenticationGateway implements AuthenticationGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  protected delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  protected async getProfile(user: User): Promise<Profile> {
    try {
      const { data: profile, error: errorProfile } = await this.cloud
        .from("profile")
        .select("*, language (*)")
        .eq("user", user.id)
        .single();

      if (errorProfile) {
        throw new Error("Error occured");
      }

      const { data: subscriptionUser, error: subscriptionError } = await this.cloud
        .from("subscription_user")
        .select("*, plan (*)")
        .eq("user", user.id)
        .single();

      if (subscriptionError) {
        throw new Error("Error occured");
      }

      return {
        id: profile.id,
        email: profile.email,
        role: profile.role,
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        username: profile.username ?? "",
        displayName: profile.displayName ?? "",
        bio: profile.bio ?? "",
        language: profile.language ?? languagesList[0],
        notifications: {
          push: profile.push,
          communication_emails: profile.communication_emails,
          security_emails: profile.security_emails,
        },
        subscription: subscriptionUser ?? null,
      };
    } catch (error) {
      console.log({ error });
      throw new Error("Error occured");
    }
  }

  async signup(email: Email, password: Password): Promise<SigninResponse | null> {
    try {
      const { error } = await this.cloud.auth.signUp({
        email: email.toString(),
        password: password.toString(),
      });

      if (error?.status === 400) {
        this.toast.show("⛔️ Error", `${error.message} !`);
        return null;
      }

      const { data } = await this.cloud.auth.signInWithPassword({
        email: email.toString(),
        password: password.toString(),
      });

      if (!data.session) {
        this.toast.show("⛔️ Error", `Can't create session !`);
        return null;
      }

      await this.cloud.auth.setSession(data.session);

      await this.delay(2000);

      const profile = await this.getProfile(data.user);

      return { profile, userUUID: data.user.id, email: email.toString() };
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
      return null;
    }
  }

  async signin(email: Email, password: Password): Promise<SigninResponse | null> {
    try {
      const { data, error } = await this.cloud.auth.signInWithPassword({
        email: email.toString(),
        password: password.toString(),
      });

      if (error) {
        this.toast.show("⛔️ Error", `${error.message} !`);
        throw new Error(error.message);
      }

      if (!data.session) {
        this.toast.show("⛔️ Error", `Can't create session !`);
        throw new Error("No session found");
      }

      const profile = await this.getProfile(data.user);
      this.toast.show("✅ Success", `Welcome ${profile.firstName ?? profile.email} !`);

      return { profile, userUUID: data.user.id, email: email.toString() };
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
      return null;
    }
  }

  async askResetPassword(email: Email): Promise<void> {
    try {
      await this.cloud.auth.resetPasswordForEmail(email.toString(), {
        redirectTo: `${APP_URL}${indexRoutes.RESET_PASSWORD}`,
      });

      this.toast.show("✅ Success", `Check your email !`);
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
    }
  }

  async resetPassword(newPassword: Password, { access_token, refresh_token }: Session): Promise<SigninResponse | null> {
    try {
      await this.cloud.auth.setSession({ access_token, refresh_token });

      const { data: info, error } = await this.cloud.auth.updateUser({ password: newPassword.toString() });

      if (error ?? !info) {
        this.toast.show("⛔️ Error", `${error?.message} !`);
        return null;
      }

      const profile = await this.getProfile(info.user);

      this.toast.show("✅ Success", `Password updated !`);

      return { profile, userUUID: info.user.id, email: profile.email };
    } catch (error) {
      this.toast.show("⛔️ Error", `Something went wrong !`);
      return null;
    }
  }

  async signout(): Promise<boolean> {
    try {
      this.toast.show("✅ Success", `See you soon !`);
      await this.cloud.auth.signOut();
      return true;
    } catch (error) {
      this.toast.show("✅ Success", `See you soon !`);
      return true;
    }
  }
}
