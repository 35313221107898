import { AppThunk } from "@store/reduxStore";
import { Task } from "@core/gateways/task/taskGateway";
import { getTaskAction } from "./getTaskAction";

export const getTask =
  (task: Task): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.getTask(task.threadId);
    dispatch(getTaskAction(query));
  };
