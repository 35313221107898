import React from "react";
import { Button } from "@app/components/ui/button";
import { ModeToggle } from "@app/components/ui/mode-toggle";
import logo from "@app/assets/images/logo.png";

const Settings: React.FC = () => {
  return (
    <div className="relative h-screen w-screen flex flex-col justify-center items-center gap-5">
      <div className="fixed top-5 right-5">
        <ModeToggle />
      </div>
      <img src={logo} width={150} height={150} />
      <Button>Disconnect</Button>
    </div>
  );
};

export default Settings;
