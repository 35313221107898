import { useAppSelector } from "@store/reduxStore";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const WorkerLayout: React.FC = () => {
  const role = useAppSelector(state => state.profile.role);

  if (role !== "WORKER") {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default WorkerLayout;
