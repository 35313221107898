import { AppThunk } from "@store/reduxStore";
import { signoutAction } from "./signoutAction";

export const signout =
  (): AppThunk =>
  async (dispatch, _, { authenticationGateway }) => {
    try {
      const result = await authenticationGateway.signout();
      dispatch(signoutAction(result));
    } catch (error) {
      console.log("Error signout: ", error);
    }
  };
