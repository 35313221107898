import { Plan } from "@core/gateways/plan/planGateway";

export const planMock: Plan = {
  id: 1,
  title: "Plan 1",
  description: [{ text: "Plan 1 description" }],
  price: "999",
  stripe_price: 99900,
  created_at: new Date(),
  priceId: "",
  currency: "usd",
};
