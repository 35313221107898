import { AppThunk } from "@store/reduxStore";
import { Email } from "@core/types/authentication/email";
import { Password } from "@core/types/authentication/password";
import { signinAction } from "./signinAction";

export const signin =
  (email: Email, password: Password): AppThunk =>
  async (dispatch, _, { authenticationGateway }) => {
    const query = await authenticationGateway.signin(email, password);
    dispatch(signinAction(query));
  };
