import Dashboard from "@app/pages/connected/admin/dashboard/Dashboard";
import Settings from "@app/pages/connected/admin/settings/Settings";
import { adminRoutes } from "./urls";

export const adminRouter = [
  {
    path: adminRoutes.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: adminRoutes.SETTINGS,
    element: <Settings />,
  },
];
