import { AppThunk } from "@store/reduxStore";
import { websocketInterceptorAction } from "./websocketInterceptorAction";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { EventTypes } from "@core/types/websocket";

export const websocketInterceptor =
  (payload: RealtimePostgresChangesPayload<Record<string, unknown>>, eventType: EventTypes): AppThunk =>
  dispatch => {
    dispatch(websocketInterceptorAction({ ...payload, eventType }));
  };
