import React from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { Loader } from "lucide-react";
import Markdown from "react-markdown";
import { useAppDispatch } from "@store/reduxStore";
import { updateTaskStatus } from "@core/usecases/task/update-task-status/updateTaskStatus";
import { Task } from "@core/gateways/task/taskGateway";

interface Props {
  onValidate: () => void;
  title: string;
  subTitle: string;
  description: string;
  loading: boolean;
  task: Task;
}

export const ValideForm: React.FC<Props> = ({ task, title, subTitle, description, loading, onValidate }) => {
  const dispatch = useAppDispatch();

  return (
    <Card className="">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subTitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <Markdown>{description}</Markdown>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button disabled={loading} variant="outline" onClick={() => dispatch(updateTaskStatus(task, "on_create"))}>
          Continue
        </Button>
        <Button disabled={loading} onClick={onValidate}>
          {loading ? <Loader className="mr-2 animate-spin" size={16} /> : null} Validate
        </Button>
      </CardFooter>
    </Card>
  );
};
