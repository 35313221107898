import { CreateTask, Message, Task, TaskGateway } from "@core/gateways/task/taskGateway";
import { tasksMock } from "@root/__mocks__/task/taskMock";

export class LocalTaskGateway implements TaskGateway {
  async getTask(threadId: string): Promise<Task | null> {
    return new Promise(resolve => {
      let task = tasksMock.find(task => task.threadId === threadId);

      if (!task) return null;

      if (!task.read) {
        task = {
          ...task,
          read: true,
        };
      }

      resolve(task);
    });
  }

  async getTasks(status?: Task["status"]): Promise<Task[]> {
    if (status) {
      const completedTasks = tasksMock.filter(task => task.status === status);

      return new Promise(resolve => {
        resolve(completedTasks);
      });
    }

    return new Promise(resolve => {
      resolve(tasksMock);
    });
  }

  async createTask(task: CreateTask): Promise<Task> {
    return new Promise(resolve => {
      resolve({
        ...task,
        id: Math.floor(Math.random() * 1000),
        created_at: new Date().toISOString(),
        title: task.title,
        subTitle: task.subTitle,
        description: task.description,
        updated_at: new Date().toISOString(),
        user: "1",
        threadId: task.threadId,
        status: "completed",
        read: true,
        planeIssueId: null,
        messages: [],
      });
    });
  }

  async updateTask(task: Task): Promise<Task> {
    return new Promise(resolve => {
      resolve(task);
    });
  }

  async postTaskMessage(message: string): Promise<Message> {
    return new Promise((_resolve, reject) => {
      reject(message);
    });
  }

  async updateTaskStatus(task: Task, status: Task["status"]): Promise<Task> {
    return new Promise(resolve => {
      resolve({
        ...task,
        status,
      });
    });
  }

  async getTaskMessages(): Promise<Message[]> {
    return new Promise(resolve => {
      resolve([]);
    });
  }

  async deleteTask(task: Task): Promise<Task | null> {
    return new Promise(resolve => {
      resolve(task);
    });
  }
}
