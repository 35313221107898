import { AppThunk } from "@store/reduxStore";
import { postTaskMessageAction } from "./postTaskMessageAction";
import { Task } from "@core/gateways/task/taskGateway";

export const postTaskMessage =
  (message: string, task: { id: Task["id"]; threadId: Task["threadId"] }, byPass?: boolean): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.postTaskMessage(message, task, byPass);
    dispatch(postTaskMessageAction(query));
  };
