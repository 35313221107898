import { ScrollArea } from "@app/components/ui/scroll-area";
import { Task } from "@core/gateways/task/taskGateway";
import { useAppSelector } from "@store/reduxStore";
import { TaskCard } from "./task-card";
import { cn } from "@lib/utils";
import { welcomeChat } from "@configs/assistant";

interface TaskListProps {
  items: Task[];
}

const TaskList: React.FC<TaskListProps> = ({ items }) => {
  const selected = useAppSelector(state => state.task.selected);

  return (
    <ScrollArea>
      <div className="flex flex-col gap-2 p-4 pt-0">
        {selected?.status === "on_create" ? (
          <button
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg p-3 text-left text-sm transition-all hover:bg-accent border-4 border-slate-600"
            )}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{selected?.title.length > 0 ? selected.title : "New task..."}</div>
                </div>
              </div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">{welcomeChat.message}</div>
          </button>
        ) : null}
        {items.map(item => (
          <TaskCard key={item.id} item={item} itemSelected={selected} />
        ))}
      </div>
    </ScrollArea>
  );
};

export default TaskList;
