import Dashboard from "@app/pages/connected/user/dashboard/Dashboard";
import SettingsLayout from "@app/layouts/user/SettingsLayout";
import Profile from "@app/pages/connected/user/settings/profile/Profile";
import Account from "@app/pages/connected/user/settings/account/Account";
import Appearance from "@app/pages/connected/user/settings/appearance/Appearance";
import Notifications from "@app/pages/connected/user/settings/notifications/Notifications";
import { userRoutes } from "./urls";

export const userRouter = [
  {
    path: userRoutes.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: userRoutes.SETTINGS,
    element: <SettingsLayout />,
    children: [
      {
        path: userRoutes.PROFILE,
        element: <Profile />,
      },
      {
        path: userRoutes.ACCOUNT,
        element: <Account />,
      },
      {
        path: userRoutes.APPEARANCE,
        element: <Appearance />,
      },
      {
        path: userRoutes.NOTIFICATIONS,
        element: <Notifications />,
      },
    ],
  },
];
