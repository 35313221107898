import React from "react";
import { Link } from "react-router-dom";
import { cn } from "@lib/utils";
import { adminRoutes } from "@app/routes/admin/urls";

const MainNav: React.FC<React.HTMLAttributes<HTMLElement> & { className?: string }> = ({ className, ...props }) => {
  return (
    <nav className={cn("flex md:items-center flex-col md:flex-row gap-4 lg:space-x-6", className)} {...props}>
      <Link to={adminRoutes.DASHBOARD} className="text-sm font-medium transition-colors hover:text-primary">
        Overview
      </Link>
      <Link
        to={adminRoutes.CUSTOMERS}
        className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
      >
        Customers
      </Link>
      <Link
        to={adminRoutes.ANALYTICS}
        className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
      >
        Analytics
      </Link>
      <Link
        to={adminRoutes.SETTINGS}
        className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
      >
        Settings
      </Link>
    </nav>
  );
};

export default MainNav;
