/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";
import { cn } from "@lib/utils";
import { Label } from "@app/components/ui/label";
import { useAppDispatch } from "@store/reduxStore";
import { Email } from "@core/types/authentication/email";
import { Button } from "@app/components/ui/button";
import { Icons } from "@app/components/ui/icon";
import { Input } from "@app/components/ui/input";
import { ROOT_DNS } from "@configs/env";
import { signup } from "@core/usecases/authentication/signup/signup";
import { useNavigate } from "react-router-dom";
import { Password } from "@core/types/authentication/password";
import { indexRoutes } from "@app/routes/urls";

interface Props {
  className?: string;
}

const SignupForm: React.FC<Props> = ({ className, ...props }) => {
  const dispatch = useAppDispatch();
  const naviagte = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (user.password !== user.confirmPassword) {
      return;
    }

    setIsLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(signup(Email.fromString(user.email), Password.fromString(user.password)));
      naviagte(indexRoutes.AUTH);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={e => onSubmit(e)}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder={`city@${ROOT_DNS}`}
              type="email"
              value={user.email}
              onChange={e => setUser({ ...user, email: e.target.value })}
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <div className="grid gap-1 mb-2">
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <Input
              id="password"
              placeholder="*********"
              type="password"
              value={user.password}
              onChange={e => setUser({ ...user, password: e.target.value })}
              autoCapitalize="none"
              disabled={isLoading}
            />
          </div>
          <div className="grid gap-1 mb-2">
            <Label className="sr-only" htmlFor="password">
              Confirm password
            </Label>
            <Input
              id="confirm_password"
              placeholder="*********"
              type="password"
              value={user.confirmPassword}
              onChange={e => setUser({ ...user, confirmPassword: e.target.value })}
              autoCapitalize="none"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <Button disabled={isLoading}>
            {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
            Sign up with Email
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
