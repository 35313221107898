import React from "react";
import { Input } from "../../ui/input";

const Search: React.FC = () => {
  return (
    <>
      <Input type="search" placeholder="Search..." className="md:w-[100px] lg:w-[300px]" />
    </>
  );
};

export default Search;
