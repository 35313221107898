export const indexRoutes = {
  BASENAME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ASK_RESET_PASSWORD: "/ask-reset-password",
  RESET_PASSWORD: "/reset-password",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy-policy",
  ALL: "*",
  AUTH: "/x",
};
