import React from "react";

const Terms: React.FC = () => {
  return (
    <>
      <div>Terms</div>
    </>
  );
};

export default Terms;
