import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import { en_EN } from "./en";
import { fr_FR } from "./fr";

const languagesProvider: Resource = {
  fr_FR,
  en_EN,
};

/**
 * Init translation module
 */
i18n
  .use(initReactI18next)
  .init({
    resources: languagesProvider,
    compatibilityJSON: "v3",
    lng: "fr_FR",
    fallbackLng: "fr_FR",
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(e => console.error("i18n init error: ", e));

export default i18n;
