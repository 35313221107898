import { toast } from "@app/components/ui/use-toast";
import { ToastGateway } from "@core/gateways/toast/toastGateway";

export class ShadcnToastGateway implements ToastGateway {
  show(title: string, description: string): void {
    toast({
      title,
      description,
    });
    return;
  }
}
