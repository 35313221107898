import { AppThunk } from "@store/reduxStore";
import { updateThemeAction } from "./updateThemeAction";
import { ThemeType } from "@core/gateways/app/appGateway";

/**
 * Async updateTheme
 *
 * @param {ThemeType} type - Language selected
 * @returns boolean if success or not
 */
export const updateTheme =
  (type: ThemeType): AppThunk =>
  async (dispatch, _, { appGateway }) => {
    const query = await appGateway.updateTheme(type);
    dispatch(updateThemeAction(query));
  };
