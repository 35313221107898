/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@app/components/ui/button";
import { APP_NAME } from "@configs/env";
import logo from "@app/assets/images/logo.png";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { indexRoutes } from "@app/routes/urls";
import ReactLoading from "react-loading";
import { signout } from "@core/usecases/authentication/signout/signout";
import { supabase } from "@services/supabase";
import { Plan } from "@core/gateways/plan/planGateway";
import { Activity, Loader, Terminal } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@app/components/ui/alert";
import { getProfile } from "@core/usecases/profile/get-profile/getProfile";

const Subscription: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profile);
  const navigate = useNavigate();

  const [plans, setPlans] = useState<Plan[] | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const params = useSearchParams()[0];

  const loadPlans = useCallback(async () => {
    setLoadingPlans(true);
    try {
      const { data: dataPlans, error } = await supabase.from("plan").select("*");
      if (error) throw new Error(error.message);

      setPlans(dataPlans);
      setSelectedPlan(dataPlans?.[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlans(false);
    }
  }, []);

  useEffect(() => {
    if (!profile.email) {
      navigate(indexRoutes.LOGIN);
      return;
    }
    if (!profile.subscription || profile.subscription.status === "EXPIRED") return;

    navigate(indexRoutes.AUTH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadPlans().catch(e => console.error(e));
  }, [loadPlans]);

  if (params.get("success") && params.get("session_id") && profile) {
    dispatch(getProfile());
    return <Navigate to={indexRoutes.AUTH} replace />;
  }

  if (!loadingPlans && !loadPlans) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Activity />
      </div>
    );
  }

  const handleSubscription = async () => {
    if (!selectedPlan) return;

    setLoadingSubscription(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data: subscriptionData, error } = await supabase.functions.invoke("create_intent_payment", {
        body: JSON.stringify({
          plan: selectedPlan,
          profile: profile,
        }),
      });

      if (!subscriptionData || error) throw new Error("Error creating subscription");

      if (subscriptionData.url) {
        window.location.replace(subscriptionData.url as string);
      } else {
        console.log(subscriptionData);
      }
    } finally {
      setLoadingSubscription(false);
    }
  };

  return (
    <>
      <div className="container relative h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-1 lg:px-0">
        <Button
          onClick={() => dispatch(signout())}
          className="absolute z-50 right-4 top-4 md:right-8 md:top-8"
          variant="outline"
        >
          Sign out
        </Button>
        <div className="absolute left-4 top-4 md:left-8 md:top-8">
          <img src={logo} className="w-12 h-12 rounded-md" />
        </div>
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img src={logo} alt="logo" width="24" height="24" className="mr-2 h-6 w-6 rounded-md" />
            {APP_NAME}
          </div>
          {loadingPlans ? (
            <div className="z-20 h-full flex justify-center items-center gap-5">
              <ReactLoading type="spin" height={26} width={26} />
              Charging plan...
            </div>
          ) : plans ? (
            <div className={`z-20 grid grid-cols-3 gap-10 h-full`}>
              {plans.map(plan => (
                <div
                  key={plan.id}
                  className="flex flex-col justify-center cursor-pointer"
                  onClick={() => {
                    !loadingSubscription && setSelectedPlan(plan);
                  }}
                >
                  <div className="text-2xl mb-3">{plan.title}</div>
                  <Alert
                    className={`min-h-96 flex flex-col justify-between ${
                      selectedPlan?.id === plan.id ? "border border-white" : ""
                    }`}
                  >
                    <Terminal className="h-4 w-4" />
                    <AlertTitle>{plan.price}$/month</AlertTitle>
                    {plan.description.map(({ text }: { text: string }) => (
                      <AlertDescription key={text} className="leading-6">
                        {text}
                      </AlertDescription>
                    ))}
                    <Button disabled={loadingSubscription} className="mt-5 w-full" onClick={() => handleSubscription()}>
                      {loadingSubscription ? <Loader className="mr-2 animate-spin" size={16} /> : null} Get started
                    </Button>
                  </Alert>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Subscription;
