import { Dependencies } from "@store/reduxStore";
import { LocalAuthenticationGateway } from "@adapters/gateways/authentication/LocalAuthenticationGateway";
import { LocalProfileGateway } from "@adapters/gateways/profile/LocalProfileGateway";
import { LocalAppGateway } from "@adapters/gateways/app/LocalAppGateway";
import { LocalTaskGateway } from "@adapters/gateways/task/LocalTaskGateway";
import { SupabaseAuthenticationGateway } from "./authentication/SupabaseAuthenticationGateway";
import { supabase } from "@services/supabase";
import { MODE } from "@configs/env";
import { SupabaseProfileGateway } from "./profile/SupabaseProfileGateway";
import { SupabaseAppGateway } from "./app/SupabaseAppGateway";
import { SupabaseTaskGateway } from "./task/SupabaseTaskGateway";
import { ShadcnToastGateway } from "./toast/ShadcnToastGateway";

const toast = new ShadcnToastGateway();

const localGateways: Dependencies = {
  appGateway: new LocalAppGateway(),
  authenticationGateway: new LocalAuthenticationGateway(),
  profileGateway: new LocalProfileGateway(),
  taskGateway: new LocalTaskGateway(),
  toastGateway: new ShadcnToastGateway(),
};

const demoGateways: Dependencies = {
  appGateway: new LocalAppGateway(),
  authenticationGateway: new LocalAuthenticationGateway(),
  profileGateway: new LocalProfileGateway(),
  taskGateway: new LocalTaskGateway(),
  toastGateway: new ShadcnToastGateway(),
};

const supabaseGateways: Dependencies = {
  appGateway: new SupabaseAppGateway(supabase, toast),
  authenticationGateway: new SupabaseAuthenticationGateway(supabase, toast),
  profileGateway: new SupabaseProfileGateway(supabase, toast),
  taskGateway: new SupabaseTaskGateway(supabase, toast),
  toastGateway: new ShadcnToastGateway(),
};

const getGateways = (mode: typeof MODE): Dependencies => {
  switch (mode) {
    case "local":
      return localGateways;
    case "demo":
      return demoGateways;
    case "production":
      return supabaseGateways;
    default:
      return localGateways;
  }
};

export const gatewaySelectors: Dependencies = getGateways("production");
