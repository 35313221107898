import { AppThunk } from "@store/reduxStore";
import { Task } from "@core/gateways/task/taskGateway";
import { deleteTaskAction } from "./deleteTaskAction";

export const deleteTask =
  (task: Task): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.deleteTask(task);
    dispatch(deleteTaskAction(query));
  };
