import React from "react";
import { cn } from "@lib/utils";
import { Link, useLocation } from "react-router-dom";
import { buttonVariants } from "../ui/button";

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    to: string;
    title: string;
  }[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const pathname = useLocation().pathname;

  return (
    <nav className={cn("flex flex-col md:flex-row lg:flex-col lg:space-x-0 lg:space-y-1", className)} {...props}>
      {items.map(item => (
        <Link
          key={item.to}
          to={item.to}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            pathname === item.to ? "bg-muted hover:bg-muted" : "hover:bg-transparent hover:underline",
            "justify-start"
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
}
