import { AppThunk } from "@store/reduxStore";
import { updateNotificationsAction } from "./updateNotificationsAction";
import { Profile } from "@core/gateways/profile/profileGateway";

export const updateNotifications =
  (notifications: Profile["notifications"]): AppThunk =>
  async (dispatch, _, { profileGateway }) => {
    const query = await profileGateway.updateNotifications(notifications);
    dispatch(updateNotificationsAction(query));
  };
