/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Agent } from "./Agent";
import { AppState } from "@store/appState";
import { AppDispatch } from "@store/reduxStore";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { updateSubscriptionProfileAction } from "@core/usecases/profile/update-subscription-profile/updateSubscriptionProfileAction";
import { supabase } from "@services/supabase";
import { Plan } from "@core/gateways/plan/planGateway";

export class SubscriptionAgent implements Agent {
  appState: AppState;
  dispatch: AppDispatch;
  payload: RealtimePostgresChangesPayload<Record<string, unknown>>;

  constructor(
    appState: AppState,
    dispatch: AppDispatch,
    payload: RealtimePostgresChangesPayload<Record<string, unknown>>
  ) {
    this.appState = appState;
    this.dispatch = dispatch;
    this.payload = payload;
  }

  async manage() {
    switch (this.payload.eventType) {
      case "DELETE":
        this.delete();
        break;
      case "INSERT":
        this.insert();
        break;
      case "UPDATE":
        await this.update();
        break;
      default:
        break;
    }
  }

  delete() {
    console.log("Subscription profile deleted");
  }

  insert() {
    console.log("Subscription profile inserted");
  }

  async update() {
    const { data: user } = await supabase.auth.getSession();
    if (!user.session || !this.appState.profile?.id) return;

    // @ts-ignore
    if (!this.payload.new.id || !this.appState.authentication.userUUID) return;

    const { data } = await supabase
      .from("subscription_user")
      .select("*, plan (*)")
      .eq("user", this.appState.authentication.userUUID)
      .single();

    if (!data || data.status !== "ACTIVE") {
      this.dispatch(updateSubscriptionProfileAction(null));
      return;
    }

    this.dispatch(
      updateSubscriptionProfileAction({
        id: data.id,
        user: data.user,
        plan: data.plan as unknown as Plan,
        status: data.status,
        created_at: data.created_at,
        updated_at: data.updated_at,
      })
    );
  }
}
