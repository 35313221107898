/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { websocketInterceptorAction } from "@core/usecases/websocket-interceptor/websocketInterceptorAction";
import { ListenerMiddleware } from "@reduxjs/toolkit";
import { websocketManager } from "@services/manager/websocketManager";
import { AppState } from "@store/appState";
import { AppDispatch, Dependencies } from "@store/reduxStore";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";

const availableActionForWebsocket = [websocketInterceptorAction.type];

export const websocketMiddleware: ListenerMiddleware<AppState, AppDispatch, Dependencies> =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  api => next => async (action: { type: string; payload: unknown }) => {
    const isWebsocketIntercepted = availableActionForWebsocket.includes(`${action.type}`);

    if (!isWebsocketIntercepted || !api.getState().profile) {
      next(action);
      return;
    }

    const appState = api.getState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const payload: RealtimePostgresChangesPayload<Record<string, unknown>> = action.payload;

    await websocketManager({ appState, dispatch: api.dispatch, payload });

    next(action);
  };
