import React from "react";

const LoadingDots: React.FC = () => {
  const circleCommonClasses = "h-1 w-1 bg-current rounded-full mb-1";

  return (
    <div className="flex text-slate-400">
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};

export default LoadingDots;
