import { AppThunk } from "@store/reduxStore";
import { updateAccountAction } from "./updateAccountAction";
import { Account } from "@core/gateways/profile/profileGateway";

export const updateAccount =
  (newAccount: Account): AppThunk =>
  async (dispatch, _, { profileGateway }) => {
    const query = await profileGateway.updateAccount(newAccount);
    dispatch(updateAccountAction(query));
  };
