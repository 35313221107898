export class Email {
  email: string;

  private constructor(email: string) {
    this.email = email;
  }

  static isValid(email: string): boolean {
    if (email.length === 0) return false;

    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);
  }

  toString(): string {
    return this.email.toLowerCase();
  }

  static fromString(email: string): Email {
    if (this.isValid(email)) {
      return new Email(email);
    }

    throw new Error("Entrer un email valide.");
  }
}
