import { AppThunk } from "@store/reduxStore";
import { getTasksAction } from "./getTasksAction";
import { Task } from "@core/gateways/task/taskGateway";

export const getTasks =
  (status?: Task["status"]): AppThunk =>
  async (dispatch, _, { taskGateway }) => {
    const query = await taskGateway.getTasks(status);
    dispatch(getTasksAction(query));
  };
