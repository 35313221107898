import React, { useEffect } from "react";
import Task from "@app/components/user/dashboard/task";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { getTasks } from "@core/usecases/task/get-tasks/getTasks";

const Dashboard: React.FC = () => {
  const defaultLayout = [265, 440, 655];
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(state => state.task.list);

  useEffect(() => {
    dispatch(getTasks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Task tasks={tasks} navCollapsedSize={4} defaultLayout={defaultLayout} />;
};

export default Dashboard;
