import { languagesList } from "@configs/language";
import { Account, Profile, ProfileGateway, UpdateProfileType } from "@core/gateways/profile/profileGateway";
import { ToastGateway } from "@core/gateways/toast/toastGateway";
import { Supabase } from "@services/supabase";

export class SupabaseProfileGateway implements ProfileGateway {
  protected cloud: Supabase;
  protected toast: ToastGateway;

  constructor(cloud: Supabase, toast: ToastGateway) {
    this.cloud = cloud;
    this.toast = toast;
  }

  async getProfile(): Promise<Profile & { uuid: string }> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;

      if (!session) {
        throw new Error("No session found");
      }

      const { data: profile, error: errorProfile } = await this.cloud
        .from("profile")
        .select("*, language (*)")
        .eq("user", session.user.id)
        .single();

      if (errorProfile) {
        throw new Error("Error occured");
      }

      const { data: subscriptionUser, error: subscriptionError } = await this.cloud
        .from("subscription_user")
        .select("*, plan (*)")
        .eq("user", session.user.id)
        .single();

      if (subscriptionError) {
        throw new Error("Error occured");
      }

      return {
        id: profile.id,
        uuid: session.user.id,
        email: profile.email,
        role: profile.role,
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        username: profile.username ?? "",
        displayName: profile.displayName ?? "",
        bio: profile.bio ?? "",
        language: profile.language ?? languagesList[0],
        notifications: {
          push: profile.push,
          communication_emails: profile.communication_emails,
          security_emails: profile.security_emails,
        },
        subscription: subscriptionUser ?? null,
      };
    } catch (error) {
      console.log({ error });
      throw new Error("Error occured");
    }
  }

  async updateNotifications(notifications: Profile["notifications"]): Promise<Profile["notifications"] | null> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;

      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { error } = await this.cloud
        .from("profile")
        .update({
          communication_emails: notifications.communication_emails,
          security_emails: notifications.security_emails,
          push: notifications.push,
        })
        .eq("user", session.user.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error: ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Notifications updated");
      return notifications;
    } catch (error) {
      this.toast.show("⛔️ Error", "Error updating notifications");
      console.log(error);
      return null;
    }
  }

  async updateProfile(newProfile: UpdateProfileType): Promise<UpdateProfileType | null> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;

      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { error } = await this.cloud
        .from("profile")
        .update({
          username: newProfile.username,
          bio: newProfile.bio,
        })
        .eq("user", session.user.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error: ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Profile updated");
      return newProfile;
    } catch (error) {
      return null;
    }
  }

  async updateAccount(account: Account): Promise<Account | null> {
    try {
      const { session } = (await this.cloud.auth.getSession()).data;

      if (!session) {
        this.toast.show("⛔️ Error", `No session found`);
        throw new Error("No session found");
      }

      const { error } = await this.cloud
        .from("profile")
        .update({
          displayName: account.displayName,
          language: account.language.id,
        })
        .eq("user", session.user.id);

      if (error) {
        this.toast.show("⛔️ Error", `Error: ${error.message}`);
        return null;
      }

      this.toast.show("✅ Success", "Account updated");
      return account;
    } catch (error) {
      return null;
    }
  }
}
