import {
  Action,
  combineReducers,
  configureStore,
  Store,
  ThunkAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import { appReducer as app } from "./reducers/appReducer";
import { authenticationReducer as authentication } from "./reducers/authenticationReducer";
import { taskReducer as task } from "./reducers/taskReducer";
import { AppGateway } from "@core/gateways/app/appGateway";
import { AuthenticationGateway } from "@core/gateways/authentication/authenticationGateway";
import { ProfileGateway } from "@core/gateways/profile/profileGateway";
import { MODE, PERSISTED_KEY } from "@configs/env";
import { profileReducer as profile } from "./reducers/profileReducer";
import { AppState } from "./appState";
import { TaskGateway } from "@core/gateways/task/taskGateway";
import { websocketMiddleware } from "./middlewares/websocketMiddleware";
import { ToastGateway } from "@core/gateways/toast/toastGateway";

/**
 * Dependencies
 */
export interface Dependencies {
  appGateway: AppGateway;
  authenticationGateway: AuthenticationGateway;
  profileGateway: ProfileGateway;
  taskGateway: TaskGateway;
  toastGateway: ToastGateway;
}

const persistConfig = {
  key: `${PERSISTED_KEY}-root`,
  version: 1,
  storage,
  blacklist: [],
};

const rootReducer = combineReducers({
  app,
  authentication,
  profile,
  task,
});

// Persist data in app (like localstorage)
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Redux initialization
 *
 * @see {@link https://redux.js.org | Redux}
 * @param dependencies
 * @returns
 */
export const initReduxStore = (dependencies: Partial<Dependencies>) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: MODE !== "production",
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: dependencies },
        serializableCheck: false,
      }).concat(websocketMiddleware),
  });
};

export type ReduxStore = Store<AppState> & {
  dispatch: ThunkDispatch<AppState, Dependencies, Action>;
};

// Type for Redux Env. to get dispatch/getState/Dependencies injections
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, Dependencies, UnknownAction>;

// Type for useAppDispatch hook
// use in core/
export type AppDispatch = ThunkDispatch<AppState, Dependencies, Action>;

/**
 * useAppDispatch Hook
 *
 * Execute fonction inside Redux to affect data in global state
 *
 * @example
 * ```ts
 * const dispatch = useAppDispatch();
 * dispatch(login(email, password));
 * ```
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * useAppSelector Hook
 *
 * Get data from global state
 *
 * @example
 * Get token from global state:
 * ```ts
 * const token = useAppSelector(state => state.authentication.token);
 * ```
 *
 * @returns AppState
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
