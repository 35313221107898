import { AppGateway, LanguageType, ThemeType } from "@core/gateways/app/appGateway";

export class LocalAppGateway implements AppGateway {
  async updateMaintenance(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  async updateLanguage(language: LanguageType): Promise<LanguageType> {
    return new Promise(resolve => {
      resolve(language);
    });
  }

  async updateTheme(theme: ThemeType): Promise<ThemeType> {
    return new Promise(resolve => {
      resolve(theme);
    });
  }

  async updateDownloadRedirection(value: boolean): Promise<boolean> {
    return new Promise(resolve => {
      resolve(value);
    });
  }
}
