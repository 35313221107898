/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Upload, User } from "lucide-react";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import LoadingDots from "../loading/loading-dot";
import { Badge } from "../ui/badge";
import Markdown from "react-markdown";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Separator } from "../ui/separator";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { ValideForm } from "./valide-form";
import { createTask } from "@core/usecases/task/create-task/createTask";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { supabase } from "@services/supabase";
import { Task } from "@core/gateways/task/taskGateway";
import { updateOnCreateTask } from "@core/usecases/task/update-oncreate-task/updateOnCreateTask";
import { getTaskMessages } from "@core/usecases/task/get-task-messages/getTaskMessages";
import { receiveTaskMessage } from "@core/usecases/task/receive-task-message/receiveTaskMessage";
import { clearTask } from "@core/usecases/task/clear-task/clearTask";
import { useToast } from "../ui/use-toast";

interface AiTaskConceptorResponse {
  result: {
    id: number;
    message: string;
    title: string;
    subTitle: string;
    description: string;
    status: "progress" | "confirmed";
  };
  threadId: string;
}

export interface DoneType {
  status: "confirmed" | "progress";
  title: string;
  subTitle: string;
  description: string;
  message?: string;
}

interface Props {
  existedTask?: Task | null;
}

const CreateTaskSection: React.FC<Props> = ({ existedTask }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profile);
  const history = useAppSelector(state => state.task.selected?.messages ?? []);

  useEffect(() => {
    if (existedTask && existedTask.threadId.length !== 0) {
      dispatch(getTaskMessages(existedTask, ["user", "assistant"]));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const messageContainer = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  useEffect(() => {
    if (history.length > 1) {
      messageContainer?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [history]);

  const handleClick = async (e?: KeyboardEvent<HTMLTextAreaElement> | null, value?: string) => {
    e?.preventDefault();

    const finalPrompt = value ?? message;

    if (finalPrompt.length === 0 || loading) return;
    setLoading(true);

    try {
      if (existedTask?.threadId?.length === 0 || existedTask?.threadId === null || !existedTask?.threadId) {
        await dispatch(
          receiveTaskMessage({
            id: 0,
            profile: profile.id,
            task: 0,
            content: finalPrompt,
            role: "user",
            created_at: new Date().toISOString(),
            threadId: "",
          })
        );
      }

      setMessage("");
      const { data } = await supabase.functions.invoke<{
        threadId: string;
        error?: string;
        result: AiTaskConceptorResponse["result"];
      }>("ai_task_conceptor", {
        body: JSON.stringify({ profile, message: finalPrompt, threadId: existedTask?.threadId ?? null }),
      });

      if (data?.error) {
        dispatch(clearTask(existedTask!));
        toast({
          title: "⛔️ Error",
          description: data.error,
        });
        return;
      }

      if (!data) {
        throw new Error("Something went wrong");
      }

      const { result } = data;

      if (existedTask?.threadId?.length === 0 || existedTask?.threadId === null || !existedTask?.threadId) {
        await dispatch(
          receiveTaskMessage({
            id: 0,
            task: result.id,
            content: data.result.message,
            role: "assistant",
            created_at: new Date().toISOString(),
            threadId: data.threadId,
          })
        );
      }

      await dispatch(
        updateOnCreateTask({
          id: result.id,
          title: result.title,
          subTitle: result.subTitle,
          description: result.description,
          threadId: data.threadId,
          status: result.status === "confirmed" ? "confirmed" : "on_create",
        })
      );
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== "Enter") {
      setMessage(e.currentTarget.value);
      return;
    }

    e.preventDefault();
    await handleClick(e);
  };

  const validateForm = () => {
    setLoadingForm(true);

    if (!existedTask) return;

    dispatch(
      createTask({
        title: existedTask.title,
        subTitle: existedTask.subTitle,
        description: existedTask.description,
        threadId: existedTask.threadId,
      })
    );

    setTimeout(() => {
      setLoadingForm(false);
    }, 3000);
  };

  const retry = async () => {
    setError(false);
    await handleClick(null, "Can you repeat please ?");
  };

  return (
    <>
      <div className="flex-1 whitespace-pre-wrap p-4 text-sm overflow-scroll">
        <main className="w-full h-full flex flex-col relative">
          <div className="flex-1">
            <div className="w-full h-full flex-grow flex flex-col justify-between">
              <div className="overflow-y-auto flex flex-col gap-5 mb-5">
                {history.map((message, idx) => {
                  if (history.length - 1 === idx && existedTask?.status === "confirmed") {
                    return (
                      <Alert key={"done.description"} className="w-4/5">
                        <User className="h-4 w-4" />
                        <AlertTitle>
                          Zoe <span className="text-xs text-slate-500">(AI Assistant design)</span>
                        </AlertTitle>
                        <AlertDescription>
                          <Markdown className="mb-5">{message.content ?? ""}</Markdown>
                          <ValideForm
                            task={existedTask}
                            onValidate={validateForm}
                            loading={loadingForm}
                            title={existedTask?.title ?? ""}
                            subTitle={existedTask?.subTitle ?? ""}
                            description={existedTask?.description ?? ""}
                          />
                        </AlertDescription>
                      </Alert>
                    );
                  }

                  if (error && history.length - 1 === idx) {
                    return (
                      <Alert key={idx} className="w-4/5">
                        <User className="h-4 w-4" />
                        <AlertTitle>
                          Zoe <span className="text-xs text-slate-500">(AI Assistant design)</span>
                        </AlertTitle>
                        <AlertDescription>
                          <Markdown className="mb-2">Error occured</Markdown>
                          <Button onClick={() => retry()}>Retry</Button>
                        </AlertDescription>
                      </Alert>
                    );
                  }

                  switch (message.role) {
                    case "assistant":
                      return (
                        <Alert key={idx} className="w-4/5">
                          <User className="h-4 w-4" />
                          <AlertTitle>
                            Zoe <span className="text-xs text-slate-500">(AI Assistant design)</span>
                          </AlertTitle>
                          <AlertDescription>
                            <Markdown>{message.content}</Markdown>
                          </AlertDescription>
                        </Alert>
                      );
                    case "user":
                      return (
                        <Alert key={idx} className="ml-auto w-4/5">
                          <User className="h-4 w-4" />
                          <AlertTitle>You</AlertTitle>
                          <AlertDescription>
                            {" "}
                            <Markdown>{message.content}</Markdown>
                          </AlertDescription>
                        </Alert>
                      );
                  }
                })}
                {loading && (
                  <Alert className="w-4/5">
                    <User className="h-4 w-4" />
                    <AlertTitle>
                      Zoe <span className="text-xs text-slate-500">(AI Assistant design)</span>
                    </AlertTitle>
                    <AlertDescription>
                      <div className="flex gap-1 items-end">
                        <span className="text-slate-500">Writing</span>
                        <LoadingDots />
                      </div>
                    </AlertDescription>
                  </Alert>
                )}
              </div>

              {history.length <= 1 ? (
                <div className="flex sticky bottom-0 w-full px-6">
                  <div className="w-full relative">
                    <div className="flex flex-wrap items-center gap-4 mb-3">
                      <Badge
                        onClick={() => handleClick(null, "I need instagram post design")}
                        className="cursor-pointer"
                      >
                        📸 I need instagram post design
                      </Badge>
                      <Badge
                        onClick={() => handleClick(null, "I would like to have a website design")}
                        className="cursor-pointer"
                      >
                        🖥️ I would like to have a website design
                      </Badge>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={`${history.length < 2 ? "hidden" : ""} h-0`} ref={messageContainer} />
            </div>
          </div>
        </main>
      </div>
      <Separator className="mt-auto" />
      <div className="p-4">
        <div className="grid gap-4">
          <Textarea
            className="p-4"
            disabled={loading || existedTask?.status === "confirmed"}
            onKeyDown={e => handleKeyDown(e)}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <div className="flex items-center">
            <Button
              disabled={loading}
              onClick={e => e.preventDefault()}
              size="icon"
              className="ml-auto mr-5"
              variant="ghost"
            >
              <Upload className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              disabled={loading || existedTask?.status === "confirmed"}
              onClick={() => handleClick()}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTaskSection;
