import Dashboard from "@app/pages/connected/worker/dashboard/Dashboard";
import Settings from "@app/pages/connected/worker/settings/Settings";
import { workerRoutes } from "./urls";

export const workerRouter = [
  {
    path: workerRoutes.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: workerRoutes.SETTINGS,
    element: <Settings />,
  },
];
