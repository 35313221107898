/* eslint-disable @typescript-eslint/no-misused-promises */
import { Upload, User } from "lucide-react";
import { FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Separator } from "../ui/separator";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { useAppDispatch, useAppSelector } from "@store/reduxStore";
import { Message, Task } from "@core/gateways/task/taskGateway";
import { postTaskMessage } from "@core/usecases/task/post-message/postTaskMessage";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";
import { supabase } from "@services/supabase";
import { receiveTaskMessage } from "@core/usecases/task/receive-task-message/receiveTaskMessage";
import { updateTaskStatus } from "@core/usecases/task/update-task-status/updateTaskStatus";

interface Props {
  selected: Task;
}

const ChatDisplay: React.FC<Props> = ({ selected }) => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(state => state.task.selected?.messages ?? []);

  const [message, setMessage] = useState("");
  const messageContainer = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const messagesTask = supabase
      .channel(`task_${selected.threadId}`)
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "message", filter: `threadId=eq.${selected.threadId}` },
        payload => {
          console.log("reveiosjoij");
          dispatch(receiveTaskMessage(payload.new as Message));
          messageContainer?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      )
      .subscribe();

    return () => {
      messagesTask.unsubscribe().catch(e => console.log(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.threadId]);

  useEffect(() => {
    if (history.length > 1) {
      messageContainer?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [history]);

  const handleClick = async (e?: FormEvent<HTMLFormElement> | null) => {
    e?.preventDefault();

    if (message.length === 0 || loading) return;

    setMessage("");
    setLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(postTaskMessage(message, { id: selected.id, threadId: selected.threadId }, true));
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== "Enter") {
      setMessage(e.currentTarget.value);
      return;
    }

    e.preventDefault();
    await handleClick();
  };

  const handleUpdateTaskLabel = (isCompleted?: boolean) => {
    const isCompletedTask = isCompleted ? "in_progress" : "completed";
    return dispatch(updateTaskStatus(selected, isCompletedTask));
  };

  return (
    <>
      <div className="flex-1 whitespace-pre-wrap p-4 text-sm overflow-scroll">
        <main className="w-full h-full flex flex-col relative">
          <div className="flex-1">
            <div className="w-full h-full flex-grow flex flex-col justify-between">
              <div className="overflow-y-auto flex flex-col gap-5 mb-5">
                <div className="border rounded-md p-4">
                  <Markdown>{selected.description}</Markdown>
                </div>
                {history.map((message, idx) => {
                  switch (message.role) {
                    case "worker":
                      return (
                        <Alert key={idx} className="w-4/5">
                          <User className="h-4 w-4" />
                          <AlertTitle>
                            Emilien <span className="text-xs text-slate-500">(Worker)</span>
                          </AlertTitle>
                          <AlertDescription>
                            <Markdown>{message.content}</Markdown>
                          </AlertDescription>
                        </Alert>
                      );
                    case "user":
                      return (
                        <Alert key={idx} className="ml-auto w-4/5">
                          <User className="h-4 w-4" />
                          <AlertTitle>You</AlertTitle>
                          <AlertDescription>
                            {" "}
                            <Markdown>{message.content}</Markdown>
                          </AlertDescription>
                        </Alert>
                      );
                  }
                })}
              </div>
              <div className={`${history.length < 2 ? "hidden" : ""} h-0`} ref={messageContainer} />
            </div>
          </div>
        </main>
      </div>
      <Separator className="mt-auto" />
      <div className="p-4">
        <div className="grid gap-4">
          <Textarea
            className="p-4"
            disabled={loading}
            onKeyDown={e => handleKeyDown(e)}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <div className="flex items-center">
            <Label htmlFor="task_done" className="flex items-center gap-2 text-xs font-normal">
              <Switch
                id="task_done"
                disabled={loading}
                checked={selected.status === "completed"}
                aria-label="Mark this task as done"
                onClick={() => handleUpdateTaskLabel(selected.status === "completed")}
              />{" "}
              Mark this task as done
            </Label>
            <Button
              disabled={loading}
              onClick={e => e.preventDefault()}
              size="icon"
              className="ml-auto mr-5"
              variant="ghost"
            >
              <Upload className="h-4 w-4" />
            </Button>
            <Button disabled={loading} onClick={() => handleClick()}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatDisplay;
