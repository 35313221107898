import { createReducer } from "@reduxjs/toolkit";
import { updateLanguageAction } from "@core/usecases/app/update-language/updateLanguageAction";
import { signoutAction } from "@core/usecases/authentication/signout/signoutAction";
import { AppState } from "@store/appState";
import { languagesList } from "@configs/language";
import { updateMaintenanceAction } from "@core/usecases/app/update-maintenance/updateMaintenanceAction";
import { updateThemeAction } from "@core/usecases/app/update-theme/updateThemeAction";
import { updateAccountAction } from "@core/usecases/profile/update-account/updateAccountAction";

const defaultLanguage = languagesList.filter(el => el.value === "en_EN")[0];

const initialState: AppState["app"] = {
  maintenance: false,
  theme: "system",
  language: defaultLanguage,
};

export const appReducer = createReducer<AppState["app"]>(initialState, builder => {
  /**
   * updateMaintenanceAction
   */
  builder.addCase(updateMaintenanceAction, (state, action) => {
    state.maintenance = action.payload;
  });

  /**
   * updateLanguageAction
   */
  builder.addCase(updateLanguageAction, (state, action) => {
    if (!action.payload) return;

    state.language = action.payload;
  });

  /**
   * updateThemeAction
   */
  builder.addCase(updateThemeAction, (state, action) => {
    if (!action.payload) return;

    state.theme = action.payload;
  });

  /**
   * Update account (listener for updateProfileAction -> language)
   */
  builder.addCase(updateAccountAction, (state, { payload }) => {
    if (!payload || !state) return;

    state.language = payload.language;
  });

  /**
   * Sign out
   */
  builder.addCase(signoutAction, state => {
    state.theme = "system";
    state.language = {
      id: languagesList[0].id,
      title: languagesList[0].title,
      value: languagesList[0].value,
    };
  });
});
